// src/frontend/en.json
var table_actions = {
  request_download: {
    accounting: "Download accounting table",
    csv: "Download selected rows on current page"
  },
  add_to_portfolios: {
    title: "Add selected assets to portfolio"
  },
  portfolios: {
    remove: {
      confirmation: {
        title: "Remove selected assets from current portfolio"
      }
    }
  },
  watchlist: {
    add: {
      button: {
        label: "Follow selected assets"
      }
    },
    remove: {
      button: {
        label: "Remove selected assets from watchlist"
      }
    }
  }
};
var table_header = {
  key_name: "Key name",
  email: "Email",
  phone: "Phone",
  free_investor_seat: "Left investor seats",
  investor_users: "Investor users",
  total_institutional_seat: "Total institutional seats",
  used_institutional_seat: "Used institutional seats",
  free_institutional_seat: "Left institutional seats",
  institutional_users: "Institutional users",
  all_users: "Team members",
  license_use: "License use",
  total_all_seats: "Total seats ",
  used_all_seats: "Total used seats",
  total_consumer_seat: "Total consumer seats",
  used_consumer_seat: "Used consumer seats",
  free_consumer_seat: "Left consumer seats",
  consumer_users: "Consumer users",
  total_investor_seat: "Total investor seats",
  used_investor_seat: "Used investor seats",
  subscription_start: "Subscription date",
  expiration_date: "Contract end date",
  related_am: "Related account manager",
  contact_user: "Contact user",
  contact_user_email: "Contact user email",
  entity_name: "Name",
  days_left: "Days left",
  contact_user_phone: "Contact user phone number",
  building_category: "Building category",
  r_unrealised_gains_and_losses_including_var_est_liq_tax: "Unrealised gains/losses including liquidation tax variation",
  sector_nb: "Sector number",
  lot_nb: "Plot number",
  region: "Region",
  r_gross_yield: "ROA",
  r_cost_price: "Cost price",
  r_rental_space: "Rental space",
  r_target_rent: "Target rental income",
  r_real_rent: "Rental income",
  r_market_value: "Market value",
  potential_total_income: "Net rent",
  r_capital_employed: "Capital employed",
  net_rent: "Net rent",
  vwap: "VWAP",
  lots_nb: "Number of plots",
  commercial_spaces_area: "Commercial area",
  residential_area: "Residential area",
  dwellings_nb: "Number of dwellings",
  building_groups_nb: "Number of building groups ",
  buildings_nb: "Number of buildings",
  r_total_area: "Total area",
  r_commercial_spaces_area: "Commercial area",
  total_area: "Total area",
  m_share_price_var: "Share price variation",
  r_residential_area: "Residential area",
  ytd_net_return: "YTD net return",
  volume: "Volume",
  three_years_total_return: "3Y total return",
  one_year_total_return: "1Y total return",
  five_years_total_return: "5Y total return",
  six_months_return: "6M net return",
  turnover: "Turnover",
  three_months_avg_volume: "3M avg volume",
  three_years_return: "3Y net return",
  m_unit_outstanding: "Outstanding units",
  three_months_return: "3M net return",
  qtd_net_return: "QTD net return",
  one_year_return: "1Y net return",
  six_months_avg_volume: "6M avg volume",
  open: "Open",
  one_year_avg_volume: "1Y avg volume",
  r2: "R2",
  mtd_net_return: "MTD net return",
  last: "Last",
  implied_volatility: "Implied volatility ",
  m_market_cap: "Market Cap",
  one_month_avg_volume: "1M avg volume",
  low: "Low",
  one_month_return: "1M net return",
  hist_volatility_90_days: "3M historical volatility",
  c_three_years_total_return: "3Y total return",
  hist_volatility_180_days: "6M historical volatility",
  five_years_return: "5Y net return",
  hist_volatility_30_days: "1M historical volatility",
  hist_volatility_250_days: "1Y historical volatility",
  high: "High",
  c_five_years_total_return: "5Y total return",
  five_years_avg_dividend_yield: "5Y avg dividend yield",
  five_days_avg_volume: "5D avg volume",
  fifty_two_weeks_high: "52W high",
  fifty_two_weeks_low: "52W low",
  m_dividend_yield: "Current dividend yield",
  beta: "Beta",
  c_total_current_agio_compounded: "Total current agio compounded",
  m_share_price: "Share price",
  alpha: "Alpha",
  c_tna_compounded: "TNA compounded",
  c_total_current_agio_after_distribution: "Total current agio after distribution",
  c_nav_compounded: "NAV compounded",
  c_tga_compounded: "TGA compounded",
  c_gav_compounded: "GAV compounded",
  r_tna_before_distribution: "TNA before distribution",
  c_current_agio_compounded: "Current agio compounded",
  c_total_agio_at_year_end: "Total agio at year end",
  c_current_agio_after_distribution: "Current agio after distribution",
  r_tna_after_distribution: "TNA after distribution",
  r_market_cap: "Market cap at year end",
  r_tga_after_distribution: "TGA after distribution",
  r_tga_before_distribution: "TGA before distribution",
  r_nav_before_distribution: "NAV before distribution",
  last_report_date: "Last report date",
  r_gav_before_distribution: "GAV before distribution",
  r_agio_at_year_end: "Agio at year end",
  c_nav_after_distribution: "NAV after distribution",
  c_agio_at_year_end: "Agio at year end",
  c_gav_after_distribution: "GAV after distribution",
  launch_date: "Launch date",
  legal_form: "Legal form",
  type: "Type",
  bloomberg_ticker: "Ticker",
  r_units_outstanding: "Units outstanding",
  m_dividend_date: "Dividend date",
  name: "Name",
  m_dividend: "Dividend",
  short_name: "Short Name",
  isin: "ISIN",
  c_weighted_average_maturity: "Weighted average maturity",
  r_roe: "ROE",
  r_roa: "ROA",
  c_weighted_average_cost_of_debt: "Weighted average cost of debt",
  r_roic: "ROIC",
  r_wault: "WAULT",
  r_roi: "ROI",
  r_roce: "ROCE",
  r_other_interest: "Other interest",
  r_rental_losses: "Rental losses",
  r_payout_ratio: "Payout ratio",
  r_other_short_term_liabilities: "Other short term liabilities",
  r_other_long_term_liabilities: "Other long term liabilities",
  r_other_revenue: "Other revenue",
  c_revenue_per_share: "Revenue per share",
  r_performance: "Performance",
  r_other_assets: "Other assets",
  r_net_income_available_for_distribution: "Net income available for distribution",
  r_nopat: "NOPAT",
  r_net_income: "Net income",
  r_ordinary_income_carried_forward_from_previous_financial_year: "Ordinary income carried forward from previous financial year",
  r_net_assets_before_estimated_liquidation_taxes: "Net assets berfore estimated liquidation taxes",
  r_net_assets_at_end_of_the_financial_year: "Net assets at end of the financial year",
  r_other_expenses: "Other expenses",
  c_median_maturity: "Median maturity",
  r_long_term_liabilities: "Long term liabilities",
  r_negative_interest: "Negative interest",
  c_median_cost_of_debt: "Median cost of debt",
  r_net_assets_at_beginning_of_the_financial_year: "Net assets at beginning of the financial year",
  r_mortgage_interest: "Mortgage interest",
  c_earnings_per_share: "Earnings per share",
  r_dividend_from_direct_rental_income: "Dividend from direct rental income",
  r_ebit_margin: "EBIT margin",
  r_ebit: "EBIT",
  r_dividend_yield: "Divident yield at year end",
  r_long_term_interest_bearing_mortgages: "Long term mortgages bearing interest",
  r_dividend_from_indirect_rental_income: "Dividend from indirect rental income",
  r_invested_capital: "Invested capital",
  c_total_cash_per_share: "Total cash per share",
  r_debt_ratio: "Debt ratio",
  c_roe: "ROE",
  r_dividend_from_capital_gains: "Dividend from capital gains",
  r_dividend_distribution: "Dividend distribution",
  c_operating_profit: "Operating profit",
  c_roce: "ROCE",
  c_roa: "ROA",
  c_payout_ratio: "Payout ratio",
  c_price_to_book_value: "Price to book value",
  c_pe_ratio: "P/E ratio",
  r_ter_nav: "TERrefNAV",
  c_current_liabilities: "Current liabilities",
  c_debt_ratio: "Debt ratio",
  c_current_ratio: "Current ratio",
  c_loan_to_value: "Loan to value",
  c_ebit_margin: "EBIT margin",
  c_net_initial_yield: "Net initial yield",
  r_ter_mv: "TERrefMV",
  r_max_share_buyback_commissions: "Max share buyback commissions",
  r_share_buyback_commissions: "Share buyback commissions",
  r_max_share_issue_commissions: "Max share issue commissions",
  r_share_price: "Share price",
  r_max_purchase_and_sale_fees: "Max purchas and sale fees",
  r_share_issue_commissions: "Share issue commissions",
  r_ter_gav: "TERrefGAV",
  r_max_management_fees: "Max management fees",
  r_max_property_management_fees: "Max property management fees",
  r_market_maker_fees: "Market maker fees",
  r_max_construction_and_works_fees: "Max construction and works fees",
  r_max_custodian_bank_fees: "Max custodian bank fees",
  r_absolute_management_fees: "Management fees",
  r_administration_of_buildings: "Administration of buildings",
  r_custodian_bank_fees: "Custodian bank fees",
  r_management_fees: "Management fees",
  r_auditing_expenses: "Auditing expenses",
  r_absolute_custodian_bank_fees: "Custodian bank fees",
  energy_intensity: "Energy intensity",
  total_co2: "CO\u2082 emissions",
  governance_rating: "G rating",
  social_rating: "S rating",
  esg_rating: "ESG rating",
  environmental: "E Score",
  governance: "G Score",
  social: "S Score",
  environmental_rating: "E rating",
  r_valuation_expenses: "Valuation expenses",
  r_valuation_and_auditing_expenses: "Valuation and auditing expenses",
  r_unrealised_capital_gains_and_losses: "Unrealised capital gains and losses",
  r_total_revenue: "Total revenue",
  esg: "ESG Score",
  r_total_properties: "Total properties",
  r_total_income: "Total income",
  r_total_liabilities: "Total liabilities",
  r_total_net_assets: "Total net assets",
  r_total_interest: "Total interest",
  r_short_term_liabilities: "Short term liabilities",
  r_taxes: "Taxes",
  r_total_expenses: "Total expenses",
  r_total_cash: "Total cash",
  r_total_dividend: "Total dividend",
  r_realised_capital_gains_and_losses: "Realised capital gains and losses",
  r_short_term_interest_bearing_mortgages: "Short term mortgages bearing interest",
  r_revenue_from_postal_and_bank_accounts: "Revenue from postal and bank accounts",
  r_realised_income: "Realised income",
  r_rental_income: "Rental income",
  r_retained_earnings: "Retained earnings",
  r_property_tax: "Property tax",
  r_property_management_expenses: "Property management expenses",
  r_profit_and_capital_taxes: "Profit and capital taxes",
  r_purchase_and_sale_fees: "Purchase and sale fee",
  r_property_management_fees: "Property management fees",
  r_estimated_liquidation_taxes: "Estimated liquidation taxes",
  r_maintenance_and_repairs_expenses: "Maintenance and repairs expenses",
  r_construction_and_works_fees: "Construction and works fees",
  r_maintenance_costs: "Maintenance costs",
  r_capital_distribution: "Capital distribution",
  r_building_expenses: "Building expenses",
  canton: "Canton",
  real_market_value: "Market value",
  estimated_area: "Area",
  zip: "Zip",
  value: "Value",
  real_roa: "ROA",
  egrid: "EGRID",
  has_building: "Has Building",
  actual_target_roa: "Target ROA",
  floor: "Floor",
  real_net_rent_monthly: "Monthly rental income",
  actual_market_value: "Market value (reported)",
  address: "Address",
  municipality_name: "Municipality",
  debt_ratio: "Debt ratio",
  roa: "ROA",
  total_net_assets: "Total net assets",
  actual_rental_status_target: "Target rental income (reported)",
  actual_rental_status_real: "Rental income (reported)",
  ter_gav: "TERrefGAV",
  ewid: "Ewid",
  street_short: "Address",
  cost_price: "Cost price",
  floors_nb: "Number of floors",
  rooms_nb: "Number of rooms",
  locality: "Locality",
  counterpart_fund: "Counterpart fund",
  label: "Label",
  construction_date: "Construction year",
  id: "ID",
  rent_defaults: "Rent defaults",
  agio: "Agio",
  actual_real_roa: "ROA",
  last_renovation_year: "Last renovation year",
  commercial_area: "Commercial area",
  market_cap: "Market capitalisation",
  duplex: "Duplex",
  date: "Year",
  real_charges_monthly: "Monthly tenant charges",
  egid: "EGID",
  rights: "Rights",
  role: "Role",
  actions: "More actions",
  area: "Area",
  fund_name: "Fund name",
  market_price: "Share price",
  state: "Invitation process",
  last_activity_date: "Last active",
  companies: "Companies",
  subscribed_date: "Subscribed since",
  last_active: "Last active",
  value_net_asset: "Net asset value",
  value_gross_asset: "Gross asset value",
  share_class_nb: "Shares nb",
  environment: {
    score: "Environment score"
  },
  total_gross_assets: "Total gross asset",
  co2: "CO\u2082",
  ter_nav: "TERrefNAV",
  dividend_date: "Dividend date",
  dividend: "Dividend",
  payout_ratio: "Payout ratio",
  ter_mv: "TERrefMV",
  roe: "ROE",
  share_class_gav: "Gross assets value",
  yearly_management_fee: "Management fee",
  ticker: "Ticker",
  rent_default_rate: "Rent default",
  roic: "ROIC",
  roi: "ROI",
  share_class_nav: "Net Asset Value",
  rate_type: "Rate type",
  start: "Start",
  real_net_rent: "Rental income",
  rate: "Rate",
  currency: "Currency",
  dividend_yield: "Dividend yield",
  end: "End",
  already_reimbursed: "Already reimbursed",
  estimated_co2_emission_per_m2: "CO\u2082 per m\xB2",
  fund_names: "Landlord name",
  real_charges: "Tenant charges",
  estimated_idc_per_m2: "Energy intensity per m\xB2",
  enterprise: "Enterprise",
  minergie_label: "Label",
  expires_at: "Expiration date",
  created_at: "Creation date",
  developer_mode: "Developer mode",
  user: "User",
  energy_source_heating: "Heating source",
  net_initial_yield: "Net initial yield",
  fund_legal_forms: "Type of landlord",
  solar_existing: "Installed solar power",
  energy_source_water: "Water heating source",
  labels: "Minergie Labels",
  ground_area: "Ground area",
  rental_losses: "Rental Losses",
  buyer_name: "Buyer",
  seller_name: "Seller",
  municipality_nb: "Municipality nb",
  potential_charges_monthly: "Monthly tenant charges",
  potential_net_rent: "Rental income",
  potential_roa: "ROA",
  potential_market_value: "Market value",
  potential_net_rent_monthly: "Monthly rental income",
  potential_gross_rent: "Gross rent",
  potential_net_rent_per_m2: "Rental income per m\xB2",
  potential_charges: "Tenant charges",
  potential_charges_per_m2: "Charges per m\xB2",
  potential_market_value_per_m2: "Market value per m\xB2",
  potential_gross_rent_per_m2: "Gross rent per m\xB2"
};
var table = {
  back_office_empty: "Empty table",
  fund_type: {
    commercial: "Commercial",
    mixed: "Mixed",
    residential: "Residential"
  },
  error: "An error occurred \u{1F647}",
  fund_legal_form: {
    unlisted_fund: "Unlisted fund",
    foundation: "Foundation",
    real_estate_firm: "Real estate firm",
    listed_fund: "Listed fund"
  },
  empty: "No data (yet) we are working on it \u{1F3C3}\u200D\u2642\uFE0F"
};
var funds = {
  financials: {
    rental_income: {
      r_others_net_rent: "Others",
      r_other_net_rent: "Others",
      r_plots_net_rent: "Plots",
      computed_by_m2: {
        title: "Rental income per m\xB2"
      },
      computed: {
        title: "Rental income"
      },
      c_net_rent_q3: "Q3",
      c_net_rent_median: "Median",
      c_mixed_net_rent: "Mixed",
      c_total_net_rent: "Total",
      c_resiential_net_rent: "Residential",
      c_commercial_net_rent: "Commercial",
      c_net_rent_q1: "Q1",
      estimated_by_m2: {
        title: "Rental income per m\xB2"
      },
      reported_by_m2: {
        title: "Rental income per m\xB2 reported"
      },
      estimated: {
        title: "Rental income"
      },
      r_net_rent_median: "Median",
      r_net_rent_q1: "Q1",
      r_net_rent_q3: "Q3",
      r_total_net_rent: "Total",
      r_mixed_net_rent: "Mixed",
      r_resiential_net_rent: "Residential",
      r_commercial_net_rent: "Commercial",
      reported: {
        title: "Rental income reported"
      },
      title: "Rental Income"
    },
    portfolio: {
      c_others_market_value: "Others",
      r_others_cost_price: "Others",
      r_other_cost_price: "Others",
      r_plots_cost_price: "Plots",
      r_other_market_value: "Others",
      r_plots_market_value: "Plots",
      computed_market_value_by_m2: {
        title: "Market value per m\xB2"
      },
      computed_market_value: {
        title: "Market value of buildings"
      },
      c_cost_price_by_m2: {
        title: "Cost price per m\xB2"
      },
      c_cost_price: {
        title: "Cost price of buildings"
      },
      r_cost_price_q3: "Q3",
      r_commercial_market_value: "Commercial",
      c_market_value_q1: "Q1",
      c_market_value_median: "Median",
      c_market_value_q3: "Q3",
      c_mixed_market_value: "Mixed",
      c_total_market_value: "Total",
      c_commercial_market_value: "Commercial",
      c_market_value_by_m2: {
        title: "Market value per m\xB2"
      },
      c_residential_market_value: "Residential",
      r_market_value_median: "Median",
      c_market_value: {
        title: "Market value of buildings estimated"
      },
      r_market_value_by_m2: {
        title: "Market value per m\xB2 reported"
      },
      r_total_market_value: "Total",
      r_market_value_q1: "Q1",
      r_mixed_market_value: "Mixed",
      r_residential_market_value: "Residential",
      r_market_value: {
        title: "Market value of buildings reported"
      },
      r_cost_price_median: "Median",
      r_market_value_q3: "Q3",
      r_mixed_cost_price: "Mixed",
      r_cost_price_by_m2: {
        title: "Cost price per m\xB2 reported"
      },
      r_cost_price_q1: "Q1",
      r_total_cost_price: "Total",
      r_cost_price: {
        title: "Cost price of buildings reported"
      },
      r_residential_cost_price: "Residential",
      r_commercial_cost_price: "Commercial",
      title: "Portfolio Value"
    },
    market_value: {
      title: "Market value"
    },
    cost_price: {
      title: "Cost price"
    },
    evolution: {
      chart: {
        title: "Chart"
      },
      title: "Evolution"
    },
    metrics: {
      balance_sheet: {
        chart_title: "Balance sheet"
      },
      income_statement: {
        chart_title: "Income statement",
        title: "Income Statement Metrics"
      },
      r_estimated_liquidation_taxes: "Estimated liquidation taxes",
      c_weighted_average_cost_of_debt: "Weighted average interest rate",
      c_earnings_per_share: "Earnings per share",
      c_weighted_average_maturity: "Weighted average maturity",
      c_current_liabilities: "Current liabilities",
      c_revenue_per_share: "Revenue per share",
      c_current_ratio: "Current Ratio",
      c_operating_profit: "Operating profit",
      c_ebit_margin: "EBIT margin",
      c_loan_to_value: "Loan-to-value",
      c_debt_ratio: "Debt ratio",
      c_total_cash_per_share: "Total cash per share",
      liquidity: {
        title: "Liquidity Metrics"
      },
      c_payout_ratio: "Payout ratio",
      c_price_to_book_value: "Price-to-book value",
      c_net_initial_yield: "Net initial yield",
      c_pe_ratio: "P/E ratio",
      c_roa: "ROA",
      c_roce: "ROCE",
      c_roe: "ROE",
      five_years_avg_dividend_yield: "5 Year avg. div. yield",
      r_payout_ratio: "Payout ratio",
      c_agio_at_year_end: "Agio at year-end",
      quantitative_valuation_ratios: {
        title: "Quantitative Valuation Ratios"
      },
      r_total_dividend: "Total dividend",
      m_dividend_yield: "Dividend yield",
      r_dividend_yield: "Dividend yield",
      r_roic: "ROIC",
      r_performance: "Performance",
      dividends: {
        title: "Dividends"
      },
      r_dividend_from_capital_gains: "Dividend from capital gains",
      r_wault: "WAULT",
      r_dividend_from_direct_rental_income: "Dividend from direct rental income",
      r_dividend_from_indirect_rental_income: "Dividend from indirect rental income",
      r_ebit_margin: "EBIT margin",
      r_roi: "ROI",
      ratios: {
        title: "Ratios"
      },
      r_roe: "ROE",
      r_debt_ratio: "Debt ratio",
      r_rental_losses: "Rental losses",
      title: "Financial Metrics"
    },
    side_menu: {
      hover_label: "Variation"
    },
    report: {
      semi_annual: "semi-annual",
      annual: "annual"
    },
    stock: {
      evolution: {
        title: "Evolution"
      },
      hist_volatility_250_days: "Historical Volatility 1-Year",
      sharpe_ratio: "Sharpe ratio",
      hist_volatility_180_days: "Historical Volatility 6-Month",
      alpha: "Alpha (5Y Monthly)",
      hist_volatility_30_days: "Historical Volatility 1-Month",
      r2: "R\xB2",
      beta: "Beta (5Y Monthly)",
      hist_volatility_90_days: "Historical Volatility 3-Month",
      three_months_avg_volume: "Average volume 3-Month",
      five_days_avg_volume: "Average volume 5-Days",
      six_months_avg_volume: "Average volume 6-Month",
      one_year_avg_volume: "Average volume 1-Year",
      one_month_avg_volume: "Average volume 1-Month",
      risk: {
        title: "Risk Statistics"
      },
      unit_outstanding: "Outstanding shares",
      turnover: "Turnover",
      volume: "Last volume",
      fifty_two_weeks_low: "52-Week Low",
      trade: {
        title: "Trade Statistics"
      },
      fifty_two_weeks_high: "52-Week High",
      five_years_return: "5-Year",
      six_months_return: "6-Month",
      one_year_return: "1-Year",
      three_years_return: "3-Year",
      three_months_return: "3-Month",
      one_month_return: "1-Month",
      ytd_net_return: "Year-to-Date (YTD)",
      qtd_net_return: "Quarter-to-Date (QTD)",
      mtd_net_return: "Month-to-Date (MTD)",
      history: {
        title: "Stock Price History"
      },
      title: "Stock performance"
    },
    stock_performance: {
      title: "Stock Performance"
    },
    fees: {
      r_max_share_buyback_commissions: "Share buyback commission",
      r_max_share_issue_commissions: "Share issue commission",
      r_max_construction_and_works_fees: "Construction and works",
      r_max_property_management_fees: "Property management fees",
      r_max_custodian_bank_fees: "Custodian bank fees",
      r_max_purchase_and_sale_fees: "Purchase and sale of real estate",
      r_ter_mv: "TERrefMV",
      maximum: {
        title: "Maximum Fees"
      },
      r_ter_nav: "TERrefNAV",
      r_max_management_fees: "Management fees",
      r_ter_gav: "TERrefGAV",
      r_share_issue_commissions: "Share issue commission",
      r_property_management_fees: "Property management fees",
      r_construction_and_works_fees: "Construction and works",
      r_custodian_bank_fees: "Custodian bank fees",
      r_management_fees: "Management fees",
      r_share_buyback_commissions: "Share buyback commission",
      r_purchase_and_sale_fees: "Purchase and sale of real estate",
      effective: {
        title: "Effective Fees"
      },
      title: "Fees"
    },
    performance: {
      title: "Performance",
      r_tga_before_distribution: "TGA before distribution",
      r_tga_after_distribution: "TGA after distribution",
      r_market_cap: "Market capitalisation",
      r_tna_after_distribution: "TNA after distribution",
      c_total_agio_at_year_end: "Agio",
      r_tna_before_distribution: "TNA before distribution",
      m_market_cap: "Market capitalisation",
      current_total_value: {
        title: "Current total value"
      },
      r_agio_at_year_end: "Agio",
      c_nav_after_distribution: "NAV after distribution",
      c_total_current_agio_after_distribution: "Total agio after distribution",
      year_end_total_value: {
        title: "Total value at year end"
      },
      c_total_current_agio_compounded: "Total agio compounded",
      c_tna_compounded: "TNA compounded",
      c_tga_compounded: "TGA compounded",
      c_nav_compounded: "NAV compounded",
      c_gav_compounded: "GAV compounded",
      r_nav_before_distribution: "NAV before distribution",
      year_end_value_per_share: {
        title: "Value per share at year end"
      },
      c_gav_after_distribution: "GAV after distribution",
      r_share_price: "Share price",
      r_gav_before_distribution: "GAV before distribution",
      m_dividend_yield: "Dividend yield",
      c_current_agio_after_distribution: "Agio after distribution",
      c_current_agio_compounded: "Agio compounded",
      current_value_per_share: {
        title: "Current value per share"
      },
      m_share_price: "Share price"
    },
    debts: {
      title: "Debt Structure"
    },
    tile: {
      measures: "Valuation measures",
      dividends: "Dividends",
      trade: "Trade statistics",
      risk_statistics: "Risk statistics",
      stock_history: "Stock price history",
      rental_income: "Rental income",
      key_performance: "Key performance",
      key_financials_per_share: "Financial performance (per share)",
      evolution: "Evolution",
      key_financials: "Financial performance (total)",
      return: "Return",
      charges: "Tenant charges",
      losses: "Losses",
      fees: "Fees"
    },
    trading: {
      title: "Trading Information"
    },
    highlights: {
      title: "Financial highlights"
    },
    revenue: {
      title: "Revenue"
    },
    charges: {
      title: "Tenant charges"
    },
    title: "Financials"
  },
  debt: {
    title: "Debt"
  },
  overview: {
    net_initial_yield: "Net initial yield",
    esg_rating: "PRESS rating",
    ter_gav: "TERrefGAV",
    roa: "ROA",
    market_cap: "Market Cap",
    total_co2: "CO\u2082",
    total_net_assets: "TNA",
    net_rent: "Rental income",
    debt_ratio: "Debt ratio",
    agio: "Agio"
  },
  environmental: {
    share: {
      r_coverage_share_fossil: "Coverage",
      r_share_fossil: "Share fossil",
      coverage: "Coverage rate",
      non_fossil: "Share non-fossil",
      fossil: "Share fossil "
    },
    co2: {
      r_coverage_co2: "Coverage",
      title: "CO\u2082 emissions",
      co2_scope_1_2_per_m2: {
        description: "CO\u2082 emissions are computed using the energy intensity multiplied by the REIDA coefficient for each building. The overall CO\u2082 emissions for the vehicle are then calculated with a weighted average of the buildings, where each building is weighted according to its heating surface.",
        key: "CO\u2082 (scope 1 + 2)"
      },
      r_co2: "Total CO\u2082 emissions",
      total_co2_scope_1_2_33: "Total CO\u2082 (scope 1 + 2 + 3.3)",
      total_co2_scope_1_2: "Total CO\u2082 (scope 1 + 2)",
      co2_scope_33_per_m2: "CO\u2082 (scope 3.3)",
      co2_scope_2_per_m2: "CO\u2082 (scope 2)",
      co2_scope_1_per_m2: "CO\u2082 (scope 1)",
      co2_scope_1_2_33_per_m2: "CO\u2082 (scope 1 + 2 + 3.3)",
      r_co2_per_m2: "CO\u2082 emissions"
    },
    idc: {
      r_coverage_idc: "Coverage",
      idc_per_m2: {
        description: "Energy intensity measures the estimated energy consumption per square meter of heating surface over one year for each building in the investment vehicle. The overall energy intensity for the vehicle is then calculated with a weighted average of the buildings, where each building is weighted according to its heating surface.",
        key: "Energy intensity"
      },
      title: "Energy intensity",
      r_idc: "Total energy",
      total_idc: "Total energy",
      r_idc_per_m2: "Energy intensity"
    },
    tile: {
      share: "Energy share",
      idc_and_co2: "Energy intensity & CO\u2082",
      environmental_risks: "Environmental risks",
      energy_source: "Energy source",
      solar: "Solar",
      climate_risk_index: "Climate risk index"
    },
    evolution: {
      chart: {
        title: "Chart"
      },
      title: "Evolution"
    },
    climate: {
      risk: {
        description: "The climate risk index is based on the exposure of buildings within investment vehicles to different environmental risks. Each risk has an equal weight in the index, while buildings are weighted by their heating surface for the aggregation at the investment vehicle level."
      }
    },
    total_estimated_idc: "Total Energy intensity",
    estimated_idc_per_m2: "Energy intensity per m\xB2",
    total_estimated_co2_emission: "Total CO\u2082",
    estimated_co2_emission_per_m2: "CO\u2082 per m\xB2",
    press_score: "Environmental PRESS score:",
    solar_existing_per_m2: "Installed solar power per m\xB2",
    title: "Environmental",
    building_with_solar_existing: "Buildings with solar panels",
    energy: {
      title: "Energy"
    },
    hail_hazard_10_years: "Hail size",
    overbank_sedimentation: "Overbank sedimentation",
    risk: {
      title: "Risk"
    },
    landslides: "Landslides",
    floods_50_years: "Floods",
    solar_existing: "Installed solar power",
    sia261: "SIA261",
    rockfalls: "Rockfalls",
    debris_flow: "Debris flow",
    avalanches: "Avalanches",
    solar_potential: "Total solar potential"
  },
  social: {
    transport_accessibility: {
      title: "Accessibility",
      transport_accessibility: {
        description: "Average of the accessibility score by public transport according to the ARE, for each building",
        key: "Accessibility by public transport",
        title: "By train"
      },
      road_accessibility: {
        description: "Average of the accessibility score by road according to the ARE, for each building",
        key: "Accessibility by road",
        title: "By road"
      }
    },
    well_being: {
      title: "Well-being",
      green_density: {
        description: "Green density contains an average of the share of surrounding hectares containing a majority of green area for each building, according to land use (NOAS04)",
        key: "Green density",
        title: "Greenery"
      },
      public_utility_density: {
        description: "This measures the share of public utility buildings (hospitals, age care facilities, schools\u2026) in the portfolio of the vehicle",
        key: "Share of public utility buildings ",
        title: "Public utility buildings"
      }
    },
    density: {
      title: "Density",
      job_density: {
        key: "Jobs density",
        description: "Job density measures the average job density in surrounding hectares, according to STATENT, per building",
        title: "Jobs density"
      },
      population_density: {
        description: "Population density measures the average population density in surrounding hectares, according to STATPOP, per building",
        key: "Population density",
        title: "Population density"
      }
    },
    accessibility: {
      primary_education: "Primary education",
      shops: "Shops",
      cultural_venues: "Cultural venues",
      transport_train: "Train",
      transport_bus: "Bus",
      restaurants: "Restaurants",
      score: "Score",
      early_years: "Early years",
      secondary_education: "Secondary education",
      aged_care: "Aged care",
      healthcare: "Healthcare",
      mean_healthcare: "Healthcare",
      mean_aged_care: "Aged care",
      mean_secondary_education: "Secondary education",
      mean_early_years: "Early years",
      title: "Accessibility",
      mean_score: "Score",
      mean_restaurants: "Restaurants",
      mean_transport_bus: "Bus",
      mean_cultural_venues: "Cultural venues",
      mean_transport_train: "Train",
      mean_shops: "Shops",
      mean_primary_education: "Primary education"
    },
    tile: {
      accessibility_others: "Other (avg. distance)",
      accessibility_transportation: "Public transportation (avg. distance)",
      accessibility_education: "Health and education (avg. distance)",
      accessibility_score: "Accessibility",
      accessibility_to_services: "Accessibility to services"
    },
    press_score: "Social PRESS score:",
    promiscuity: {
      per_100_m2: "Per 100 m\xB2",
      departure_per_inhabitant: "Departure per inhabitant",
      title: "Population density",
      per_bedroom: "Per bedroom",
      arrival_per_inhabitant: "Arrival per inhabitant",
      tile: {
        migration: "Migration",
        occupancy: "Occupancy"
      }
    },
    demographic: {
      tile: {
        ages: "Ages",
        gender: "Gender",
        foreigners: "Foreigners"
      },
      title: "Demographic"
    },
    title: "Social"
  },
  governance: {
    tile: {
      minergie: "Minergie",
      press_score: "PRESS \u2013 Public Real Estate Sustainability Scores",
      certification: "Certification",
      auditors: "Auditors",
      valuation_expert: "Valuation experts",
      property_management: "Property management",
      ownership: "Ownership"
    },
    press_score: "Governance PRESS score:",
    minergie_buildings: "Proportion of minergie buildings",
    ranking: "Ranking",
    esg: "ESG",
    governance: "Governance",
    social: "Social",
    environmental: "Environmental",
    custodian_bank: "Custodian bank",
    director: "Fund director",
    manager: "Fund manager",
    certificates_rating: {
      title: "Certificates and rating"
    },
    organisation: {
      title: "Organisation"
    },
    title: "Governance"
  },
  composition: {
    building_groups_nb: "Number of building groups ",
    semi_annual_report_date: "Last report (semi-annual)",
    annual_report_date: "Last report (annual)",
    report_date: "Last report",
    total_area: "Total area",
    lots_nb: "Number of plots",
    lot_area: "Plot area",
    office_area: "Total office area",
    fund_age: "Age of real estate Portfolio",
    residential_area: "Total residential area",
    industrial_area: "Total industrial area",
    commercial_area: "Total commercial area",
    buildings_nb: "Number of buildings",
    report_age: "Age of current real estate report"
  },
  environment: {
    energy_distribution: {
      value_string: "Heated area",
      label_string: "Heating source"
    }
  },
  description: {
    distribution: {
      value_string: "Area",
      label_string: "Canton"
    },
    ranking: "Fund ranking:",
    tile: {
      distribution: "Distribution",
      administrative: "Administrative",
      designation: "Designation",
      stock: "Stock",
      composition: "Composition"
    },
    composition: {
      title: "Composition"
    },
    title: "Description",
    identification: {
      title: "Identification"
    }
  },
  transactions: {
    value: {
      purchase: "purchased",
      sale: "sold"
    },
    title: "Transactions"
  },
  page: {
    title: "All swiss funds"
  },
  performance: {
    total_expense_ratio_ref_nav: "Total expense ratio (TER_NAV)",
    return_on_capital_employed: "ROCE",
    return_on_asset: "ROA",
    five_y_avg_dividend_yield: "5Y avg. dividend yield",
    dividend_yield: "Dividend yield",
    dividend: "Last dividend",
    turnover: "Turnover",
    volume: "Average Volume",
    fifty_two_w_low: "52-Week Low",
    fifty_two_w_high: "52-Week High",
    three_y_return: "3-Year",
    one_y_return: "1-Year",
    three_m_return: "3-Month",
    one_m_return: "1-Month",
    hist_volatility_250_days: "Historical Volatility 1-Year",
    hist_volatility_180_days: "Historical Volatility 6-Month",
    hist_volatility_90_days: "Historical Volatility 3-Month",
    hist_volatility_30_days: "Historical Volatility 1-Month",
    r2: "R\xB2",
    beta: "Beta (5Y Monthly)",
    alpha: "Alpha (5Y Monthly)",
    volume_suffix: "Units",
    wault_suffix: "years",
    mtd_net_return: "Month-to-date (MTD)",
    debt_financing_ratio: "Debt ratio",
    total_gross_assets: "Total gross asset",
    return_on_equity: "Return on equity (ROE)",
    distribution_yield: "Distribution yield",
    rent_default_rate: "Rental losses",
    ebit_margin: "EBIT margin",
    yearly_management_fee: "Management fee",
    total_expense_ratio_ref_mv: "Total expense ratio (TER_MV)",
    payout_ratio: "Payout ratio",
    relative_agio: "Agio",
    total_net_assets: "Total net assets",
    market_capitalization: "Market capitalisation",
    qtd_net_return: "Quarter-to-date (QTD)",
    total_expense_ratio_ref_gav: "Total expense ratio (TER_GAV)",
    return_on_investment: "Return on investment (ROI)",
    ytd_net_return: "Year-to-date (YTD)",
    wault_label: "Weighted average unexpired lease term (WAULT)",
    return_on_invested_capital: "Return on invested capital (ROIC)"
  },
  noright: {
    button: "Contact account manager",
    details: "The institutions module shows comprehensive information about wide range of swiss real estate entities.",
    subtitle: "This feature requires a more complete plan.",
    title: "Institutions"
  },
  financials_per_share: {
    share_market_price: "Share price",
    share_class_gav: "Gross asset value (GAV)",
    last_distribution: "Last distribution",
    distribution_value: "Distribution value",
    share_class_nb: "Outstanding shares",
    share_class_nav: "Net asset value (NAV)"
  },
  designation: {
    valor_no: "Valor number",
    bloomberg_ticker: "Bloomberg Ticker",
    instrument_name: "Name of the instrument",
    ticker: "Common name",
    isin: "ISIN",
    currency: "Currency"
  },
  stock: {
    last_distribution: "Last distribution",
    distribution_frequency: "Frequency of distribution",
    units_class: "Class of units",
    units_nb: "Outstanding shares",
    year_end: "Year-end date",
    distribution_date: "Distribution date",
    launch_date: "Launch date"
  },
  administrative: {
    name: "Share Registry",
    benchmark: "Benchmark",
    manager: "Fund manager",
    custodian_bank: "Custodian bank",
    director: "Fund director",
    domicile: "Domicile of the fund",
    benchmark_bloomberg_ticker: "Benchmark Ticker"
  },
  assets: {
    title: "Inventory"
  }
};
var units = {
  r_others_net_rent: "CHF",
  r_others_cost_price: "CHF",
  c_others_market_value: "CHF",
  r_market_value_per_m2: "CHF/m\xB2",
  r_cost_price_per_m2: "CHF/m\xB2",
  r_real_rent_per_m2: "CHF/m\xB2",
  r_other_net_rent: "CHF",
  r_plots_net_rent: "CHF",
  r_unrealised_gains_and_losses_including_var_est_liq_tax: "CHF",
  r_coverage_share_fossil: "%",
  r_share_fossil: "%",
  r_coverage_idc: "%",
  r_coverage_co2: "%",
  total_co2_scope_1_2_33: "kg/yr",
  co2_scope_33_per_m2: "kg/m\xB2/yr",
  co2_scope_1_2_33_per_m2: "kg/m\xB2/yr",
  co2_scope_1_2_per_m2: "kg/m\xB2/yr",
  idc_per_m2: "kWh/m\xB2/yr",
  share: {
    coverage: "%",
    non_fossil: "%",
    fossil: "%"
  },
  total_co2_scope_1_2: "kg/yr",
  total_co2_scope_1_2_3: "kg/yr",
  total_idc: "kWh/yr",
  co2_scope_1_per_m2: "kg/m\xB2/yr",
  co2_scope_2_per_m2: "kg/m\xB2/yr",
  total_co2_scope_1: "kg/yr",
  total_co2_scope_2: "kg/yr",
  total_co2_scope_33: "kg/yr",
  co2_scope_1_2_3_per_m2: "kg/m\xB2/yr",
  r_co2: "kg/yr",
  r_co2_per_m2: "kg/m2/yr",
  r_idc: "kWh/yr",
  r_idc_per_m2: "kWh/m2/yr",
  transport_accessibility: "pts",
  road_accessibility: "pts",
  green_density: "%",
  public_utility_density: "%",
  job_density: "FTE/ha",
  population_density: "ppl./ha",
  vwap: "CHF",
  c_market_value: "CHF",
  c_net_rent: "CHF",
  r_gross_yield: "%",
  r_real_rent: "CHF",
  r_target_rent: "CHF",
  r_cost_price: "CHF",
  r_rental_space: "m\xB2",
  stock_volume: "Units",
  r_market_value: "CHF",
  six_months_avg_volume: "Units",
  five_days_avg_volume: "Units",
  one_year_avg_volume: "Units",
  three_months_avg_volume: "Units",
  one_month_avg_volume: "Units",
  five_years_return: "%",
  one_year_return: "%",
  fifty_two_weeks_low: "CHF",
  six_months_return: "%",
  three_years_return: "%",
  unit_outstanding: "Units",
  turnover: "CHF",
  qtd_net_return: "%",
  mtd_net_return: "%",
  fifty_two_weeks_high: "CHF",
  r_cost_price_q3: "CHF/m\xB2",
  ytd_net_return: "%",
  three_months_return: "%",
  r_cost_price_median: "CHF/m\xB2",
  one_month_return: "%",
  r_total_cost_price: "CHF",
  c_market_value_q1: "CHF/m\xB2",
  r_cost_price_q1: "CHF/m\xB2",
  c_market_value_q3: "CHF/m\xB2",
  c_total_market_value: "CHF",
  r_residential_cost_price: "CHF",
  r_commercial_cost_price: "CHF",
  r_mixed_cost_price: "CHF",
  c_residential_market_value: "CHF",
  c_market_value_median: "CHF/m\xB2",
  c_mixed_market_value: "CHF",
  r_market_value_q1: "CHF/m\xB2",
  c_commercial_market_value: "CHF",
  r_market_value_q3: "CHF/m\xB2",
  r_mixed_market_value: "CHF",
  r_commercial_market_value: "CHF",
  r_total_market_value: "CHF",
  r_net_rent_q1: "CHF/m\xB2",
  r_net_rent_median: "CHF/m\xB2",
  r_net_rent_q3: "CHF/m\xB2",
  r_residential_market_value: "CHF",
  r_max_custodian_bank_fees: "%",
  r_max_construction_and_works_fees: "%",
  r_residential_net_rent: "CHF",
  r_max_share_buyback_commissions: "%",
  r_max_share_issue_commissions: "%",
  r_custodian_bank_fees: "%",
  r_ter_mv: "%",
  r_property_management_fees: "%",
  r_ter_nav: "%",
  r_share_issue_commissions: "%",
  r_purchase_and_sale_fees: "%",
  r_share_buyback_commissions: "%",
  r_max_management_fees: "%",
  r_ter_gav: "%",
  c_earnings_per_share: "CHF",
  r_estimated_liquidation_taxes: "CHF",
  c_operating_profit: "CHF",
  r_management_fees: "%",
  c_total_cash_per_share: "CHF",
  c_loan_to_value: "%",
  c_debt_ratio: "%",
  c_current_ratio: "%",
  c_weighted_average_maturity: "yr",
  c_current_liabilities: "CHF",
  c_weighted_average_cost_of_debt: "%",
  c_payout_ratio: "%",
  c_roe: "%",
  c_price_to_book_value: "%",
  c_pe_ratio: "%",
  c_agio_at_year_end: "%",
  c_net_initial_yield: "%",
  c_roa: "%",
  c_roce: "%",
  r_total_dividend: "CHF",
  m_dividend_yield: "%",
  r_dividend_yield: "%",
  r_payout_ratio: "%",
  r_dividend_from_indirect_rental_income: "CHF",
  r_rental_losses: "%",
  r_performance: "%",
  r_roic: "%",
  r_roi: "%",
  r_debt_ratio: "%",
  r_roe: "%",
  r_ebit_margin: "%",
  c_mixed_net_rent: "CHF",
  c_resiential_net_rent: "CHF",
  c_total_net_rent: "CHF",
  c_net_rent_q3: "CHF/m\xB2",
  c_commercial_net_rent: "CHF",
  r_resiential_net_rent: "CHF",
  r_market_value_median: "CHF/m\xB2",
  c_net_rent_q1: "CHF/m\xB2",
  c_net_rent_median: "CHF/m\xB2",
  r_mixed_net_rent: "CHF",
  c_total_agio_at_year_end: "CHF",
  r_total_net_rent: "CHF",
  r_commercial_net_rent: "CHF",
  r_tna_after_distribution: "CHF",
  c_tga_compounded: "CHF",
  r_tna_before_distribution: "CHF",
  r_tga_after_distribution: "CHF",
  c_tna_compounded: "CHF",
  r_tga_before_distribution: "CHF",
  c_total_current_agio_compounded: "CHF",
  r_market_cap: "CHF",
  r_nav_before_distribution: "CHF",
  c_total_current_agio_after_distribution: "CHF",
  r_agio_at_year_end: "%",
  r_share_price: "CHF",
  c_current_agio_compounded: "%",
  c_nav_after_distribution: "CHF",
  r_gav_before_distribution: "CHF",
  c_gav_after_distribution: "CHF",
  c_nav_compounded: "CHF",
  c_current_agio_after_distribution: "%",
  m_share_price: "CHF",
  real_charges_monthly: "CHF",
  potential_charges_monthly: "CHF",
  c_five_years_total_return: "%",
  c_three_years_total_return: "%",
  potential_net_rent_monthly: "CHF",
  beta: "%",
  alpha: "%",
  c_median_cost_of_debt: "%",
  real_net_rent_monthly: "CHF",
  hist_volatility_30_days: "%",
  implied_volatility: "%",
  hist_volatility_90_days: "%",
  potential_net_rent_per_m2: "CHF",
  r2: "%",
  hist_volatility_250_days: "%",
  hist_volatility_180_days: "%",
  potential_charges_per_m2: "CHF",
  tx_price: "CHF",
  potential_roa: "%",
  estimated_area: "m\xB2",
  solar_existing: "kW",
  duplex: "m\xB2",
  real_market_value: "CHF",
  potential_charges: "CHF",
  potential_market_value_per_m2: "CHF",
  real_roa: "CHF",
  potential_market_value: "CHF",
  r_capital_employed: "CHF",
  ground_area: "m\xB2",
  potential_total_income: "CHF",
  r_total_area: "m\xB2",
  r_residential_area: "m\xB2",
  commercial_spaces_area: "m\xB2",
  one_year_total_return: "%",
  m_share_price_var: "%",
  r_commercial_spaces_area: "m\xB2",
  open: "CHF",
  three_years_total_return: "%",
  five_years_total_return: "%",
  last: "CHF",
  m_market_cap: "CHF",
  low: "CHF",
  high: "CHF",
  r_wault: "yr",
  c_gav_compounded: "CHF",
  r_roce: "%",
  m_dividend: "CHF",
  r_other_revenue: "CHF",
  c_revenue_per_share: "CHF",
  r_other_short_term_liabilities: "CHF",
  r_roa: "%",
  r_other_interest: "CHF",
  r_other_expenses: "CHF",
  r_ordinary_income_carried_forward_from_previous_financial_year: "CHF",
  r_net_income: "CHF",
  r_other_long_term_liabilities: "CHF",
  r_net_assets_at_end_of_the_financial_year: "CHF",
  r_other_assets: "CHF",
  r_nopat: "CHF",
  r_net_income_available_for_distribution: "CHF",
  r_net_assets_at_beginning_of_the_financial_year: "CHF",
  r_net_assets_before_estimated_liquidation_taxes: "CHF",
  r_negative_interest: "CHF",
  r_long_term_liabilities: "CHF",
  r_ebit: "CHF",
  r_invested_capital: "CHF",
  r_long_term_interest_bearing_mortgages: "CHF",
  r_dividend_distribution: "CHF",
  r_mortgage_interest: "CHF",
  r_dividend_from_direct_rental_income: "CHF",
  c_median_maturity: "yr",
  c_weighted_average_interest_rate: "%",
  r_dividend_from_capital_gains: "CHF",
  c_ebit_margin: "%",
  r_max_purchase_and_sale_fees: "%",
  r_auditing_expenses: "CHF",
  r_max_property_management_fees: "%",
  r_valuation_expenses: "CHF",
  r_administration_of_buildings: "CHF",
  r_market_maker_fees: "CHF",
  r_total_net_assets: "CHF",
  r_absolute_custodian_bank_fees: "CHF",
  r_absolute_management_fees: "CHF",
  r_valuation_and_auditing_expenses: "CHF",
  r_unrealised_capital_gains_and_losses: "CHF",
  r_total_revenue: "CHF",
  r_total_properties: "CHF",
  r_total_liabilities: "CHF",
  r_total_interest: "CHF",
  r_total_expenses: "CHF",
  r_taxes: "CHF",
  r_total_cash: "CHF",
  r_total_income: "CHF",
  r_short_term_liabilities: "CHF",
  r_retained_earnings: "CHF",
  r_short_term_interest_bearing_mortgages: "CHF",
  r_rental_income: "CHF",
  r_realised_income: "CHF",
  r_revenue_from_postal_and_bank_accounts: "CHF",
  r_property_management_expenses: "CHF",
  r_property_tax: "CHF",
  r_realised_capital_gains_and_losses: "CHF",
  r_profit_and_capital_taxes: "CHF",
  r_maintenance_and_repairs_expenses: "CHF",
  r_maintenance_costs: "CHF",
  r_capital_distribution: "CHF",
  r_building_expenses: "CHF",
  r_construction_and_works_fees: "%",
  rental_losses: "%",
  energy: {
    distribution_area: "m\xB2"
  },
  net_initial_yield: "%",
  gross_rent: "CHF",
  description: {
    distribution_area: "m\xB2",
    distribution: "%"
  },
  rate: "%",
  market_value_per_m2: "CHF/m\xB2",
  portfolios: {
    distribution_area: "m\xB2",
    hail_hazard_10_years_average: "%",
    accessibility: "m",
    overbank_sedimentation: "%",
    avalanches: "%",
    debris_flow: "%",
    rockfalls: "%",
    floods_50_years: "%",
    distribution: "%",
    landslides: "%"
  },
  wault: "yr",
  share_class_nb: "Units",
  roic: "%",
  roi: "%",
  household_size: "ppl.",
  false: "No",
  roe: "%",
  true: "Yes",
  immigration: "%",
  inhabitants_per_100_m2: "ppl.",
  emigration: "%",
  inhabitants_per_room: "ppl.",
  accessibility_index: "%",
  actual_rental_status: "CHF",
  income_per_taxpayer: "CHF",
  cost_price: "CHF",
  charges_per_m2: "CHF/m\xB2",
  rent_per_m2: "CHF/m\xB2",
  price_per_m2: "CHF/m\xB2",
  total_tenant_charges: "CHF",
  residential_net_rent_m2: "CHF/m\xB2",
  commercial_net_rent_m2: "CHF/m\xB2",
  funds: {
    transactions: {
      value: "CHF"
    },
    overbank_sedimentation: "%",
    hail_hazard_10_years: "%",
    sia261: "%",
    rockfalls: "%",
    debris_flow: "%",
    landslides: "%",
    avalanches: "%",
    floods_50_years: "%"
  },
  roa: "%",
  energy_intensity: "kWh/m\xB2/yr",
  ter_mv: "%",
  ter_nav: "%",
  climate_risk_index: "%",
  market_price_var: "%",
  market_price: "CHF",
  five_years_avg_dividend_yield: "%",
  return_on_asset: "%",
  total_expense_ratio_ref_nav: "%",
  return_on_capital_employed: "%",
  dividend_yield: "%",
  dividend: "CHF",
  potential_net_rent: "CHF",
  built_up: {
    area: "m\xB2",
    ratio: "%"
  },
  real_charges: "CHF",
  demographic: {
    ages: "%"
  },
  real_net_rent: "CHF",
  yearly_management_fee: "%",
  ebit_margin: "%",
  rent_default_rate: "%",
  total_expense_ratio_ref_mv: "%",
  total_expense_ratio_ref_gav: "%",
  debt_financing_ratio: "%",
  return_on_equity: "%",
  return_on_invested_capital: "%",
  distribution_yield: "%",
  return_on_investment: "%",
  relative_agio: "%",
  distribution_value: "CHF",
  market_capitalization: "CHF",
  payout_ratio: "%",
  total_gross_assets: "CHF",
  share_class_nav: "CHF",
  share_class_gav: "CHF",
  swiss_net_rent: "CHF",
  canton_rent: "CHF",
  share_market_price: "CHF",
  press_score: "/10",
  birth: "%",
  death: "%",
  marriage: "%",
  volume: "m\xB3",
  divorce: "%",
  lot_area: "m\xB2",
  area: "m\xB2",
  transport_bus: "m",
  surface: "m\xB2",
  transport_train: "m",
  healthcare: "m",
  secondary_education: "m",
  shops: "m",
  restaurants: "m",
  primary_education: "m",
  early_years: "m",
  hail_hazard_10_years_average: "cm",
  climate_normals_precipitation_1991_2020: "mm/yr",
  aged_care: "m",
  cultural_venues: "m",
  climate_normals_sunshine_duration_1991_2020: "%",
  market_cap: "CHF",
  ter_gav: "%",
  market_value: "CHF",
  total_co2: "kg/m\xB2/yr",
  charges: "CHF",
  total_net_assets: "CHF",
  climate_normals_temperature_1991_2020: "\xB0C",
  decibels: "dB",
  total_area: "m\xB2",
  industrial_area: "m\xB2",
  agio: "%",
  debt_ratio: "%",
  net_rent: "CHF",
  office_area: "m\xB2",
  commercial_area: "m\xB2",
  idc_m2: "kWh/m\xB2/yr",
  co2_m2: "kg/m\xB2/yr",
  solar_potential: "kWh/yr",
  co2: "kg/yr",
  residential_area: "m\xB2",
  idc: "kWh/yr",
  solar_existing_per_m2: "kWh/m\xB2"
};
var boarding_page = {
  totp_setup: {
    notification: {
      privileged_session_reached: {
        message: "Privileged session time out. Please login again."
      }
    }
  },
  register: {
    notification: {
      privileged_session_reached: {
        message: "For security reason, the registration process needs to be completed within 30 minutes. Please request another invitation link to start over the registration.",
        title: "Privileged session time out"
      },
      changed_email: {
        message: "Your information have been updated successfully - You should have received a new email to verify your new email address.",
        title: "Email address changed"
      },
      flow_expiration: {
        message: "The flow has expired - Contact your administrator.",
        title: "Expired"
      }
    },
    repeat_password: "Repeat password",
    error: {
      repeat_password: "Passwords must be identic"
    },
    button: "Save",
    subtitle: "Setup your account:",
    title: "Welcome to Quanthome_"
  },
  login: {
    missing_otp: {
      description: "The One-time Password (OTP) for this account is not configured or is incorrect. It the problem persists, please contact your admin to resolve the issue.",
      title: "Invalid OTP Setup"
    },
    aal2: {
      submit_button: "Submit",
      forgot_button: "Use recovery codes",
      subtitle: "Please complete the second authentication challenge.",
      title: "Enter OTP (One-time password)"
    },
    aal1: {
      submit_button: "Login",
      forgot_button: "Forgot/Reset password?",
      subtitle: "Welcome back!",
      title: "Login to your account"
    },
    failed: {
      title: "Error while logging"
    },
    abort: "Cancel"
  },
  recovery_code: {
    missing: {
      title: "Invalid recovery code",
      description: "The recovery code for this account is not defined or has expired. Please contact the support of Quanthome to resolve the issue."
    },
    abort_button: "Cancel",
    faq: {
      lock: {
        answer: "Please contact your admin for recovery.",
        question: "Locked out?"
      },
      what_is_recovery: {
        answer: "Recovery code is the code you received when you bind your device for the first time. You should have kept it somewhere safe.",
        question: "What is a recovery code?"
      },
      description: "If your are unable to access your mobile device, enter your recovery code to verify your identity."
    },
    form: {
      button: "Verify"
    },
    title: "Two-factor recovery",
    reset: {
      success: {
        message: "You can now bind a new device.",
        title: "Recovery code is correct"
      }
    }
  },
  show_recovery: {
    explain: {
      regenerate_code: "When you generate new recover codes, you must download or print the new codes. Your old codes won\u2019t work anymore."
    },
    label: {
      regenerate_code: "Generate new recovery codes"
    },
    button: {
      regenerate_code: "Generate new recovery codes",
      proceed: "Proceed",
      print: "Print",
      copied: "Copied",
      copy: "Copy",
      download: "Download"
    },
    warning: "Keep your recovery code in a safe spot. These codes are the last resort for accessing your account in case you lose your second factor. If you cannot find this code, <0>you will lose</0> access to the account.",
    subtitle: "Never lose your recovery code.",
    title: "Recovery code"
  },
  verify_mail: {
    abort: "Will do that later",
    step: {
      sent_email: {
        button: "Verify code",
        success: {
          message: "You're email is now verified",
          title: "Verification complete"
        },
        description: "Write the code you just received by email",
        subtitle: "Verify your email address"
      },
      choose_method: {
        button: "Send code",
        success: {
          message: "An email to finish your verification has been sent to you",
          title: "Code sent"
        },
        description: "Type your email address to verify it",
        subtitle: "Verify your email address"
      }
    },
    title: "Finish your setup"
  },
  otp: {
    failed: {
      title: "Error while setting OTP device"
    },
    button: {
      abort: "Will do that later",
      verify: "Verify my device with TOPT"
    },
    step: {
      type_code: "When OTP ready, verify by typing your TOTP",
      scan_qr: "Scan this QR code to set your trusted device via your OTP app",
      trusted_device: "Open your favorite OTP app on your trusted device"
    },
    legend: "(T)OTP*: (Time-based) One Time Password",
    subtitle: "Dual factor authenticator to best protect data.",
    title: "Setting your OTP"
  },
  forgot_password: {
    success: {
      message: "You can now log in with your new password.",
      title: "Password changed successfully"
    },
    button: {
      cancel: "Cancel recovery",
      verify: "Verify my code",
      send: "Send my code",
      save: "Save my password"
    },
    last_step: "You can now change your password for a new one:",
    subtitle: "Don\u2019t panic, everything will be fine...",
    title: "Forgot your password"
  }
};
var asset = {
  get_building_from_group: {
    empty: {
      message: "There is no building in this group",
      title: "No building in this group"
    }
  },
  description: {
    dwellings: {
      rooms_nb: "Rooms number",
      ewid: "EWID",
      surface: "Surface area",
      floor: "Floor",
      construction_date: "Construction date",
      type: "Type",
      category: "Category",
      class: "Class",
      status: "Status",
      land_use: "Land use",
      city: "City",
      egid: "EGID"
    },
    lots: {
      built_up_ratio: "Built-up ratio",
      built_up_area: "Built-up area",
      municipality: "Municipality",
      egrid: "EGRID",
      surface: "Surface",
      sector: "Sector",
      number: "Number",
      label: "Label",
      type: "Type",
      land_use: "Land use",
      city: "City"
    },
    amenities: {
      dwellings: {
        imputed_mansarde: "Mansarde",
        imputed_attique: "Attique",
        imputed_balcony: "Balcony",
        imputed_cellar: "Cellar"
      },
      buildings: {
        imputed_cachet: "Cachet",
        imputed_near_transports: "Near transports",
        imputed_near_highway: "Near highway",
        imputed_bike_room: "Bike room",
        imputed_elevator: "Elevator",
        fallout_shelter: "Fallout shelter"
      }
    },
    buildings: {
      dwellings_nb: "Number of dwellings",
      floors_nb: "Number of floors",
      volume: "Total volume",
      area: "Ground area",
      total_area: "Total area",
      industrial_area: "Industrial area",
      commercial_area: "Commercial area",
      office_area: "Office area",
      residential_area: "Residential area",
      construction_date: "Construction date",
      type: "Type",
      category: "Category",
      class: "Class",
      status: "Status",
      land_use: "Land use",
      official_nb: "Official number",
      city: "City",
      egid: "EGID"
    }
  }
};
var preset = {
  modal: {
    confirm_delete: {
      cancel: "Back",
      proceed: "Proceed",
      text: "Are you sure you want to delete this screen?",
      title: "Delete screen"
    }
  }
};
var tile = {
  wrong: {
    message: "Something wrong happens"
  },
  empty: {
    message: "Missing data"
  },
  side_menu: {
    country: "Country",
    canton: "Canton",
    municipality: "Municipality"
  }
};
var error = {
  something_went_wrong: {
    title: "Something went wrong"
  },
  email: {
    format: "Your email is not well written"
  },
  notfound: {
    button: "Go back",
    subtitle: "Sorry, the page you are looking for doesn't exist.",
    title: "Page not found",
    code: "Error 404"
  }
};
var companies = {
  button: {
    clear_fund_cache: "Clear fund cache",
    clear_all_cache: "Clear all cache",
    clear_cache: "Clear cache",
    check_states: "Check states",
    give_am_rights: "Give am rights",
    refresh_roles: "Refresh roles",
    give_default_roles: "Give default roles"
  },
  modal: {
    confirm_delete: {
      text: "Are you sure you want to delete this/these company(ies)?",
      title: "Confirm to delete"
    }
  },
  notifications: {
    success_delete_enterprises: "Companies successfully deleted",
    no_slot_available: "No slots available",
    success_create_users: "Users successfully created"
  }
};
var balance_sheet = {
  label: {
    r_tga_before_distribution: "Total assets",
    r_tna_before_distribution: "TNA before distribution",
    asset_total: "Total assets",
    r_total_liabilities: "Total liabilities",
    r_net_assets_before_estimated_liquidation_taxes: "Net assets before estimated liquidation taxes",
    r_estimated_liquidation_taxes: "Estimated liquidation taxes",
    r_other_long_term_liabilities: "Other long term liabilities",
    other_liabilities: "Other liabilities",
    r_total_net_assets: "Total net assets",
    r_total_cash: "Total cash",
    r_total_properties: "Total properties",
    r_other_assets: "Other assets",
    r_short_term_liabilities: "Short term liabilities",
    r_short_term_interest_bearing_mortgages: "Short term interest bearing mortgages",
    r_other_short_term_liabilities: "Other short term liabilities",
    r_long_term_liabilities: "Long term liabilities",
    r_long_term_interest_bearing_mortgages: "Long term interest bearing mortgages",
    total_liabilities: "Total liabilities",
    total_asset: "Total asset",
    date: "Date"
  },
  unit: {
    chf: "CHF"
  },
  legend: {
    group: {
      liabilities: "Liabilities",
      asset: "Asset",
      equity: "Equity"
    }
  }
};
var actions = {
  screener: {
    filter_header: {
      header: "Columns",
      label: "Set visibility of the columns"
    },
    per_page: {
      label: "Rows per page",
      header: "Number of rows"
    },
    preset: {
      delete: "Delete screen",
      copy: "Copy screen",
      reset: "Reset screen",
      save: "Save screen"
    },
    filter: {
      search: "Filters",
      edit: "Edit filter",
      save: "Save filter",
      remove: "Remove filter"
    }
  },
  add_to_watchlist: {
    title: "Add to watchlist"
  },
  update: {
    processing: {
      done: "Operation is done successfully. Page will be updated soon.",
      title: "Updating",
      message: "Operation will be done soon."
    }
  },
  watchlist: {
    remove: {
      button: {
        label: "Unfollow"
      },
      success: {
        message: "Asset removed from your watchlist"
      }
    },
    add: {
      button: {
        label: "Follow"
      },
      success: {
        message: "Asset added to your watchlist"
      }
    }
  },
  companies: {
    create: "Create company",
    delete: "Delete"
  },
  portfolios: {
    remove: {
      success: {
        message: "{{ asset }}(s) removed from your portfolio"
      }
    }
  },
  request_download: {
    csv: "Download CSV",
    message: "Request created"
  },
  add_to_portfolios: {
    adding_portfolio: {
      message: "Adding {{asset}}(s) to your portfolio",
      title: "Adding"
    },
    success_add_portfolio: {
      message: "Adding {{asset}} to your {{portfolio_state}} portfolio",
      title: "Successful"
    },
    success_create_portfolio: {
      title: "Successful",
      message: "New portfolio has been created"
    },
    confrm_modal: {
      title: "Dwellings already contained",
      buttons: {
        cancel: "Back ",
        confirm: "Proceed"
      }
    },
    confirm_modal: {
      text_1: "Portfolio already contains dwelling(s) of {{building_label}}.",
      text_2: "Do you want to override them with the building instead?"
    },
    modal: {
      portfolio_name: "Portfolio Name",
      add_to_existing: "Add to existing",
      name: "Name",
      create_button: "Create",
      new: "New"
    },
    title: "Add to portfolio"
  }
};
var common = {
  assets: {
    pascalcase: "Assets"
  },
  comparables: {
    pascalcase: "Comparables"
  },
  real_estate_firm: {
    pascalcase: "Real estate firm"
  },
  foundation: {
    pascalcase: "Investment foundation"
  },
  unlisted_fund: {
    pascalcase: "Unlisted fund"
  },
  listed_fund: {
    pascalcase: "Listed fund"
  },
  transactions: {
    pascalcase: "Transactions"
  },
  keys: "keys",
  key: "key",
  enterprise_state: {
    state: "Contract type",
    ended: "Ended",
    exempt: "Exempt",
    trial_end: "End of trial",
    unpaid: "Unpaid",
    subscribed: "Subscribed",
    trial: "Trial",
    examination: "Examination"
  },
  developer_mode: {
    deactivated: "Deactivated",
    activated: "Activated",
    state: "Developer mode"
  },
  institutions: {
    pascalcase: "Institutions"
  },
  connection_lost: {
    reload_button: "Reload",
    message_2: "Application cannot work as expected",
    message_3: "Please reload the page.",
    title: "Connection lost",
    message_1: "You might not be connected to internet anymore"
  },
  warning_msg: "Operation on {{ failed }} has failed.",
  warning: "Warning",
  financials: {
    array: {
      total: "Total",
      residential: "Residential",
      office: "Office",
      commercial: "Commercial",
      industrial: "Industrial"
    }
  },
  edit: "Edit",
  assign_contact_user: "Assign as Contact user",
  create: "Create",
  slot: {
    consumer: "Consumer",
    institutional: "Institutional",
    investor: "Investor"
  },
  role: {
    user: "User",
    manager: "Manager",
    owner: "Owner"
  },
  removing: "removing",
  error: "Something happened",
  success: "Successful",
  delete_user: "Delete user",
  cancel_button: "Cancel",
  confirm_button: "Confirm",
  notification: {
    success: {
      title: "Success"
    }
  },
  lots: {
    pascalcase: "Plots",
    lowercase: "plots"
  },
  error_message: "Something happened",
  buildings: {
    lowercase: "buildings",
    pascalcase: "Buildings"
  },
  dwellings: {
    lowercase: "dwellings",
    pascalcase: "Dwellings"
  },
  portfolios: {
    pascalcase: "Portfolios"
  },
  funds: {
    pascalcase: "Funds"
  }
};
var income_statement = {
  label: {
    r_provisions_for_future_works: "Provisions for future works",
    r_unrealised_gains_and_losses_including_var_est_liq_tax: "Unrealised gains/losses including liquidation taxes variation",
    r_absolute_custodian_bank_commission_fees: "Absolute custodian bank commission fees",
    r_absolute_property_management_fees: "Absolute property management fees",
    r_absolute_directors_fees: "Absolute directors fees",
    r_absolute_legal_fees: "Absolute legal fee",
    r_net_income_available_for_distribution: "Net income available for distribution",
    r_absolute_custodian_bank_distribution_fees: "Absolute custodian bank distribution fees",
    r_interest_on_building_rights: "Interest on building rights",
    r_total_provisions: "Total provisions",
    r_provisions_for_future_work: "Provisions for future work",
    r_provisions_for_doubtful_debtors: "Provisions for doubtful debtors",
    r_ordinary_maintenance_and_repairs_expenses: "Ordinary maintenance and repairs expenses",
    r_extraordinary_maintenance_and_repairs_expenses: "Extraordinary maintenance and repairs expenses",
    r_administration_expenses: "Administration expenses",
    r_other_administration_expenses: "Other administration expenses",
    r_total_expenses: "Total expenses",
    r_total_revenue: "Total revenue",
    other_net_income_available_for_distribution: "Other net income available for distribution",
    r_capital_distribution: "Capital distribution",
    r_dividend_distribution: "Dividend distribution",
    r_retained_earnings: "Retained earnings",
    r_ordinary_income_carried_forward_from_previous_financial_year: "Ordinary income carried forward from previous financial year",
    r_net_income: "Net income",
    r_realised_capital_gains_and_losses: "Realised capital gains and losses",
    r_unrealised_capital_gains_and_losses: "Unrealised capital gains and losses",
    r_realised_income: "Realised income",
    r_total_income: "Total income",
    r_other_expenses: "Other expenses",
    r_absolute_custodian_bank_fees: "Absolute custodian bank fees",
    r_market_maker_fees: "Market maker fees",
    r_property_management_expenses: "Property management expenses",
    r_property_tax: "Property tax",
    r_profit_and_capital_taxes: "Profit and capital taxes",
    r_taxes: "Taxes",
    r_administration_of_buildings: "Administration of buildings",
    r_valuation_expenses: "Valuation expenses",
    r_auditing_expenses: "Auditing expenses",
    r_valuation_and_auditing_expenses: "Valuation and auditing expenses",
    r_absolute_management_fees: "Absolute management fees",
    r_rental_income: "Rental income",
    r_revenue_from_postal_and_bank_accounts: "Revenue from postal and bank accounts",
    r_negative_interest: "Negative interest",
    r_other_revenue: "Other revenue",
    r_mortgage_interest: "Mortgage interest",
    r_maintenance_and_repairs_expenses: "Maintenance and repairs expenses",
    r_other_interest: "Other interest",
    r_total_interest: "Total interest",
    r_building_expenses: "Building expenses",
    r_maintenance_costs: "Maintenance costs",
    total_net_income_available_for_distribution: "Total net income available for distribution",
    total_expenses: "Total expenses",
    total_revenues: "Total revenues",
    retained_earnings: "Retained earnings",
    rental_income: "Rental income",
    revenue_from_postal_and_bank_accounts: "Revenue from postal and bank accounts",
    negative_interest: "Negative interest",
    other_revenue: "Other revenue",
    interest_expenses: "Interest expenses",
    maintenance_costs: "Maintenance costs",
    taxes: "Taxes",
    valuation_and_auditing_expenses: "Valuation and auditing expenses",
    other_expenses: "Other expenses",
    net_income: "Net income",
    realised_gains_losses: "Realised gains/losses",
    realised_income: "Realised income",
    unrealised_gains_losses_including_liquidation_taxes_variation: "Unrealised gains/losses including liquidation taxes variation",
    total_income: "Total income",
    ordinary_income_carried_forward: "Ordinary income carried forward",
    capital_distribution: "Capital distribution",
    dividend_distribution: "Dividend distribution",
    year: "Year"
  },
  legend: {
    group: {
      distribution_of_realised_income: "Distribution of realised income",
      income_statement: "Income statement"
    }
  },
  unit: {
    chf: "CHF"
  }
};
var global = {
  chart: {
    legend: {
      none: "No heating",
      m_market_cap: "Market cap",
      m_share_price: "Share price",
      co_2_scope_1_2_33_per_m_2: "CO\u2082 scope 1 + 2 + 3.3",
      total_co_2_scope_1_2_33: "Total CO\u2082 scope 1 + 2 + 3.3",
      total_co_2_scope_1_2: "Total CO\u2082 scope 1 + 2",
      total_idc: "Total Energy",
      idc_per_m_2: "Energy intensity ",
      co_2_scope_1_per_m_2: "CO\u2082 scope 1",
      co_2_scope_2_per_m_2: "CO\u2082 scope 2",
      co_2_scope_33_per_m_2: "CO\u2082 scope 3.3",
      co_2_scope_1_2_per_m_2: "CO\u2082 scope 1 + 2",
      total_co_2_scope_33: "Total CO\u2082 scope 3.3",
      total_co_2_scope_2: "Total CO\u2082 scope 2",
      total_co_2_scope_1: "Total CO\u2082 scope 1",
      r_cost_price: "Cost price of buildings",
      r_share_price: "Share price",
      r_target_rent: "Target rental income",
      r_tna_before_distribution: "TNA before distribution",
      r_tga_before_distribution: "TGA before distribution",
      r_market_cap: "Market cap",
      r_debt_ratio: "Debt ratio",
      r_ebit_margin: "Ebit margin",
      r_rental_losses: "Rental losses",
      r_roe: "ROE",
      r_dividend_yield: "Dividend yield",
      r_payout_ratio: "Payout ratio",
      r_nav_before_distribution: "NAV before distribution",
      r_market_value: "Market value of buildings",
      c_market_value: "Market value of buildings",
      c_net_rent: "Rental income",
      r_real_rent: "Real rent",
      liabilities: "Liabilities",
      equity: "Equity",
      asset: "Asset",
      default: "Amount",
      roa: "ROA",
      surface: "Surface",
      co_2_expected: "CO\u2082 (expected)",
      co_2_listed: "CO\u2082",
      gav: "GAV",
      nav: "NAV",
      rent_expected: "Rental income (expected)",
      rent_listed: "Rental income",
      realised_gains_losses: "Realised gains/losses",
      realised_income: "Realised income",
      net_income_available_for_distribution: "Net income available for distribution",
      ordinary_income_carried_forward: "Ordinary income carried forward",
      total_income: "Total income",
      unrealised_gains_losses_including_liquidation_taxes_variation: "Unrealised gains/losses including liquidation taxes variation",
      net_income: "Net income",
      expenses: "Expenses",
      revenues: "Revenues",
      total_net_asset: "Total net asset",
      other_long_term_liabilities: "Other long term liabilities",
      long_term_interest_bearing_mortgages: "Long term interest bearing mortgages",
      long_term_liabilities: "Long term liabilities",
      other_short_term_liabilities: "Other short term liabilities",
      short_term_interest_bearing_mortgages: "Short term interest bearing mortgages",
      short_term_liabilities: "Short term liabilities",
      other_asset: "Other asset",
      total_properties: "Total properties",
      other: "Other",
      non_fossil: "Non fossil",
      fossil: "Fossil",
      tessin: "Ticino",
      zentralschweiz: "Zentralschweiz",
      espace_mitelland: "Espace Mitelland",
      ostschweiz: "Ostschweiz",
      region_lemanique: "R\xE9gion l\xE9manique",
      zurich: "Z\xFCrich",
      nordwestschweiz: "Nordwestschweiz",
      cash_holding_and_balances_at_sight: "Cash holding and balances at sight",
      woman: "Woman",
      man: "Man",
      legend: "Legend",
      amount: "Amount",
      date: "Date"
    },
    tooltip: {
      potential: "Expected",
      real: "Listed"
    }
  },
  assets: {
    lot: "lot",
    dwelling: "dwelling",
    building: "building"
  }
};
var asset_page = {
  environmental: {
    co2: {
      total_co2_scope_1_2_33: "Total CO\u2082 (scope 1 + 2 + 3.3)",
      total_co2_scope_1_2: "Total CO\u2082 (scope 1 + 2)",
      co2_scope_33_per_m2: "CO\u2082 (scope 3.3)",
      co2_scope_2_per_m2: "CO\u2082 (scope 2)",
      co2_scope_1_per_m2: "CO\u2082 (scope 1)",
      co2_scope_1_2_33_per_m2: "CO\u2082 (scope 1 + 2 + 3.3)",
      co2_scope_1_2_per_m2: {
        key: "CO\u2082 (scope 1 + 2)",
        description: "CO\u2082 emissions are computed using the energy intensity multiplied by the REIDA coefficient for each building."
      }
    },
    idc: {
      idc_per_m2: {
        description: "Energy intensity measures the estimated energy consumption per square meter of heating surface over one year the building.",
        key: "Energy intensity"
      },
      total_idc: "Total energy"
    },
    risk: {
      chart: {
        description: "The climate risk index is calculated based on the exposure to different environmental risks. Each risk has an equal weight in the index.",
        suffix: "/10"
      },
      title: "Risks",
      risk: {
        title: "Environmental risks"
      },
      climate_risk_index: {
        title: "Climate risk index"
      },
      overbank_sedimentation: "Overbank sedimentation",
      debris_flow: "Debris flow",
      rockfalls: "Rockfalls",
      sia261: "Seismic zone SIA 261",
      hail_hazard_10_years_average: "Hail size",
      avalanches: "Avalanches",
      landslides: "Landslides",
      floods_50_years: "Floods"
    },
    energy: {
      idc: {
        estimated_idc: "Energy intensity",
        total_official_idc: "Total Energy intensity (official)",
        estimated_idc_per_m2: "Energy intensity per m\xB2",
        total_estimated_idc: "Total Energy intensity",
        official_idc_per_m2: "Energy intensity per m\xB2 (official)",
        title: "Energy intensity"
      },
      co2: {
        estimated_co2: "CO\u2082 emissions ",
        official_co2_emission_per_m2: "CO\u2082 per m\xB2 (official)",
        total_official_co2_emission: "Total CO\u2082 (official)",
        estimated_co2_emission_per_m2: "CO\u2082 per m\xB2",
        total_estimated_co2_emission: "Total CO\u2082",
        title: "CO\u2082 emissions"
      },
      title: "Energy",
      heating: {
        energy_generator_water_1: "Hot water generator",
        energy_source_water_1: "Hot water energy source",
        energy_source_heating_1: "Heating energy source",
        energy_generator_heating_1: "Heating generator",
        title: "Heating generation"
      },
      solar: {
        solar_existing: "Installed solar power",
        solar_potential: "Solar potential",
        title: "Solar"
      }
    },
    title: "Environmental",
    environment: {
      climate: {
        title: "Climate",
        temperature: {
          title: "Average temperature"
        },
        sunlight: {
          title: "Average sunlight"
        },
        rain: {
          title: "Average rainfall"
        }
      },
      pollution: {
        rail_noise_day: {
          label: "Day train noise"
        },
        rail_noise_night: {
          label: "Night train noise"
        },
        road_noise_day: {
          label: "Day traffic noise"
        },
        road_noise_night: {
          label: "Night traffic noise"
        },
        title: "Noise pollution"
      },
      title: "Environment"
    }
  },
  social: {
    income_and_taxes: {
      income: {
        description: "Description",
        subject: "Income per taxpayer",
        title: "Income",
        label: "Income"
      },
      profit: {
        label: "Profit"
      },
      capital: {
        label: "Capital"
      },
      wealth: {
        label: "Wealth"
      },
      municipality_tax: {
        title: "Municipality tax multipliers"
      },
      canton_tax: {
        title: "Canton tax multipliers"
      },
      title: "Income and taxes"
    },
    transportation_accessibility: {
      transport_accessibility: {
        description: "Accessibility score by public transport according to the ARE",
        label: "Accessibility by public transport",
        title: "By train"
      },
      road_accessibility: {
        description: "Accessibility score by road according to the ARE",
        label: "Accessibility by road",
        title: "By road"
      },
      title: "Accessibility"
    },
    well_being: {
      public_utility: {
        key: "Public utility building",
        title: "Public utility building"
      },
      title: "Well-being"
    },
    density: {
      population_density: {
        title: "Population density",
        description: "Population density measures the average population density in surrounding hectares, according to STATPOP",
        key: "Population density"
      },
      title: "Density",
      green_density: {
        description: "Green density measure the share of surrounding hectares containing a majority of green area, according to land use (NOAS04)",
        title: "Greenery",
        key: "Green density"
      },
      job_density: {
        title: "Jobs density",
        description: "Job density measures the average job density in surrounding hectares, according to STATENT",
        key: "Jobs density"
      },
      occupancy_per_bedroom: {
        subject: "Inhabitants",
        title: "Occupancy per bedroom"
      },
      occupancy_per_m2: {
        subject: "Inhabitants"
      },
      occupancy_per_100_m2: {
        title: "Occupancy per 100 m\xB2"
      },
      occupancy: {
        per_bedroom: "Per bedroom",
        per_meter: "Per 100 m\xB2",
        title: "Occupancy"
      },
      migration: {
        departure: "Departure per inhabitant",
        arrival: "Arrival per inhabitant",
        title: "Migration"
      },
      income: {
        subject: "Income per taxpayer",
        title: "Income per taxpayer"
      }
    },
    accessibility: {
      chart: {
        suffix: "/10",
        subject: "Index",
        title: "Accessibility"
      },
      goods_and_services: {
        title: "Other"
      },
      transportation: {
        title: "Public transportation"
      },
      health_and_edu: {
        title: "Health & Education"
      },
      title: "Distances to goods and services",
      distance: {
        title: "Accessibility"
      },
      transport_bus: "Bus",
      transport_train: "Train",
      restaurants: "Restaurants",
      shops: "Shops",
      secondary_education: "Secondary education",
      primary_education: "Primary education",
      healthcare: "Healthcare",
      early_years: "Early years",
      cultural_venues: "Cultural venues",
      aged_care: "Aged care"
    },
    demographic: {
      migration: {
        title: "Migration"
      },
      emigration: {
        subject: "Emigration"
      },
      immigration: {
        subject: "Immigration"
      },
      death: {
        subject: "Deaths"
      },
      birth: {
        subject: "Births"
      },
      vital_statistics: {
        title: "Vital statistics"
      },
      civil_status: {
        death: "Death p.a.",
        birth: "Births p.a.",
        divorce: "Divorce p.a.",
        marriage: "Marriage p.a.",
        household: "Mean household size",
        title: "Civil status"
      },
      foreigners: {
        title: "Foreigners"
      },
      gender: {
        title: "Gender"
      },
      ages: {
        title: "Ages"
      },
      title: "Demographic"
    },
    title: "Social"
  },
  financials: {
    units: {
      commercial_rent: "CHF",
      residential_rent: "CHF",
      total_rent: "CHF"
    },
    ratio_metric: {
      market_value_reported: "Market value (reported)",
      commercial_spaces: {
        label: "Commercial spaces"
      },
      dwellings: {
        label: "Dwellings"
      },
      total_tenant_charges: "Total tenant charges",
      market_value: "Market value",
      charges_per_m2: "Tenant charges per m\xB2",
      price_per_m2: "Price per m\xB2",
      rent_per_m2: "Rental income per m\xB2",
      charges: {
        title: "Charges"
      },
      average: "Average",
      industrial: "Industrial",
      office: "Office",
      commercial: "Commercial",
      residential: "Residential",
      rent: {
        title: "Rental income"
      },
      price: {
        title: "Price"
      },
      title: "Metrics per square meter"
    },
    unit: {
      chf: "CHF"
    },
    charges: {
      title: "Tenant charges"
    },
    total: {
      label: "Total"
    },
    commercial_spaces: {
      label: "Commercial spaces"
    },
    dwellings: {
      label: "Dwellings"
    },
    performance: {
      title: "Performance",
      rental_income: "Rental income"
    },
    metrics: {
      title: "Metrics per square meter"
    },
    tile: {
      charges: "Tenant charges",
      evolution: "Evolution",
      rental_income: "Rental income"
    },
    revenue: {
      title: "Revenue"
    },
    comparables: {
      title: "Comparables"
    },
    title: "Financials"
  },
  description: {
    tile: {
      parent_description: "Parent Description",
      buildings: {
        parent_description: "Lot description"
      },
      usage: "Usage",
      geometry: "Geometry",
      dwellings: {
        parent_description: "Building parent description"
      },
      description: "Description",
      location: "Location",
      addresses: "Addresses",
      administrative_information: "Administrative Information",
      amenities: "Amenities"
    },
    title: "Description",
    identification: {
      title: "Identification"
    },
    general_information: {
      title: "General Information"
    }
  },
  valuation: {
    cap_rate_method: {
      income_inputs: {
        other: {
          suffix: "CHF",
          label: "Other income"
        },
        parking: {
          label: "Parking",
          suffix: "CHF"
        },
        title: "Income inputs",
        office_net_income: {
          label: "Office net income",
          suffix: "CHF"
        },
        commercial: {
          label: "Commercial income",
          suffix: "CHF"
        },
        industrial: {
          label: "Industrial",
          suffix: "CHF"
        },
        residential_net_income: {
          suffix: "CHF",
          label: "Residential net income"
        },
        dwelling_net_rent: {
          label: "Dwelling net rent",
          suffix: "CHF"
        }
      },
      cap_rate_inputs: {
        title: "Cap rate inputs",
        capitalization_rate: {
          label: "Capitalization rate",
          suffix: "%"
        }
      },
      result: {
        total_acquisition_cost: {
          suffix: "CHF",
          label: "Total acquisition cost"
        },
        purchase_price: {
          label: "Purchase price",
          suffix: "CHF"
        },
        title: "Result"
      },
      title: "Cap rate method",
      description: {
        content: "The capitalization rate (cap rate) method applied to real estate is used to estimate a property's potential value. It is calculated as rental income divided by the target return. Cap rates don't account for future value appreciation or financing costs.",
        title: "Description"
      }
    },
    parameters: {
      buyer_seller: {
        other_fees_chf: {
          suffix: "CHF",
          label: "Other fees"
        },
        other_fees_pts: {
          suffix: "%",
          label: "Other fees"
        },
        asset_manager_fees: {
          label: "Asset manager fees",
          suffix: "%"
        },
        share_buyers_seller: {
          label: "Share buyer / seller",
          suffix: "%"
        },
        title: "Buyer / Seller"
      },
      transaction_fees: {
        property_transfert_fees: {
          suffix: "%",
          label: "Property transfert fees"
        },
        title: "Transaction fees",
        land_registration_fees: {
          label: "Land registration fees",
          suffix: "%"
        },
        notary_fees: {
          label: "Notary fees",
          suffix: "%"
        }
      },
      due_diligence_cost: {
        other_valuations: {
          suffix: "CHF",
          label: "Other valuations"
        },
        valuation: {
          suffix: "CHF",
          label: "Valuation"
        },
        title: "Due diligence cost"
      },
      title: "Parameters"
    },
    qh_method: {
      title: "Quanthome's method",
      result: {
        total_acquisition_cost: {
          label: "Total acquisition cost",
          suffix: "CHF"
        },
        purchase_price: {
          suffix: "CHF",
          label: "Purchase price"
        },
        title: "Result"
      },
      description: {
        title: "Description",
        content: "Quanthome's machine learning algorithms estimate the expected target return for the building and predict a value based on the estimated cash flows."
      }
    },
    title: "Valuation"
  },
  governance: {
    item: {
      ownership: {
        link: "link",
        registry: "Land registry",
        owner: "Owner"
      },
      certification: {
        minergy: "Minergie"
      },
      management: {
        website: "Website",
        name: "Name"
      }
    },
    tile: {
      certification: "Certification",
      management: "Property management",
      ownership: "Ownership"
    },
    organisation: {
      title: "Organisation"
    },
    title: "Governance"
  },
  overview: {
    description: {
      market_value: "Market Value",
      idc: "Energy intensity",
      net_rent: "Rental income",
      co2: "CO\u2082",
      roa: "ROA",
      charges: "Tenant charges"
    }
  },
  assets: {
    title: "Inventory"
  }
};
var portfolios = {
  environmental: {
    evolution: {
      chart: {
        title: "Chart"
      }
    },
    estimated_co2_emission_per_m2: "CO\u2082 per m\xB2",
    total_estimated_co2_emission: "Total CO\u2082",
    estimated_idc_per_m2: "Energy intensity per m\xB2",
    total_estimated_idc: "Total Energy intensity",
    overbank_sedimentation: "Overbank sedimentation",
    rockfalls: "Rockfalls",
    solar_potential: "Total solar potential",
    floods_50_years: "Floods",
    building_with_solar_existing: "Buildings with solar panels",
    solar_existing_per_m2: "Installed solar power per m\xB2",
    debris_flow: "Debris flow",
    landslides: "Landslides",
    solar_existing: "Installed solar power",
    avalanches: "Avalanches",
    hail_hazard_10_years_average: "Hail size"
  },
  financials: {
    evolution: {
      chart: {
        title: "Chart"
      },
      title: "Evolution"
    },
    performance: {
      evolution: {
        title: "Evolution"
      },
      title: "Performance"
    }
  },
  environment: {
    energy_distribution: {
      value_string: "Heated area",
      label_string: "Heating source"
    }
  },
  description: {
    distribution: {
      value_string: "Area",
      label_string: "Canton"
    }
  },
  noright: {
    button: "Contact account manager",
    details: "The portfolio module allows you to have global vision on a real estate portfolio, from financial to ESG data.",
    subtitle: "This feature requires a more complete plan.",
    title: "Portfolios"
  },
  overview: {
    type: {
      empty: "Empty",
      dwelling: "Dwelling",
      mixed: "Mixed",
      building: "Building"
    },
    potential_roa: "ROA",
    potential_charges: "Charges",
    potential_net_rent: "Rental income",
    potential_market_value: "Market value"
  },
  modal: {
    confirm_delete: {
      notification: {
        text: "Portfolio has been deleted"
      },
      cancel: "Back",
      proceed: "Proceed",
      text: "Are you sure you want to delete this portfolio?",
      title: "Delete portfolio"
    }
  },
  create_modal: {
    title: "New portfolio"
  },
  notification: {
    success_create: {
      message: "The portfolio has been successfully created",
      title: "Successfully created"
    }
  },
  widget: {
    energy: {
      title: "Energy"
    },
    risk: {
      title: "Risk"
    },
    identification: {
      title: "Identification"
    }
  },
  tiles: {
    solar: {
      title: "Solar"
    },
    energy_source: {
      title: "Energy source"
    },
    risk_index: {
      title: "Climate risk index"
    },
    risks: {
      title: "Environmental risks"
    },
    composition: {
      title: "Composition"
    },
    designation: {
      title: "Designation"
    },
    distribution: {
      title: "Distribution"
    },
    idc_and_co2: {
      title: "Energy intensity & CO\u2082"
    }
  },
  designation: {
    created_at: "Creation date",
    updated_at: "Last update",
    name: "Name of the instrument",
    fund_age: "Age of real estate Portfolio",
    commercial_area: "Total commercial area",
    industrial_area: "Total industrial area",
    individual_dwellings_nb: "Number of individual dwellings",
    office_area: "Total office area",
    dwellings_nb: "Number of dwellings",
    buildings_nb: "Number of buildings",
    total_area: "Total area",
    residential_area: "Total residential area",
    type: "Asset composition of Portfolio"
  }
};
var portfolio = {
  environmental: {
    evolution: {
      title: "Evolution"
    }
  }
};
var candle_stick_chart = {
  unit: {
    units: "Units",
    chf: "CHF"
  },
  label: {
    close: "Close",
    high: "High",
    low: "Low",
    open: "Open",
    start_date: "Beginning",
    end_date: "Ending",
    volume: "Volume",
    turnover: "Turnover",
    date: "Date"
  }
};
var screener = {
  filters: {
    labels: {
      potential_net_rent_per_m2: "Potential net rent per m\xB2",
      potential_market_value: "Potential market value",
      potential_market_value_per_m2: "Potential market value per m\xB2",
      potential_charges: "Potential charges",
      potential_charges_per_m2: "Potential charges per m\xB2",
      potential_roa: "ROA",
      area: "Plot surface",
      potential_net_rent: "Potential net rent",
      undefined: "-",
      columns_to_ignore: "Columns",
      sort: "Sort",
      roa: "ROA",
      charges: "Tenant charges",
      price: "Price",
      rent: "Rental income",
      net_rent: "Rental income",
      idc: "Energy intensity",
      co2: "CO\u2082",
      label: "Label",
      energy_source_heating: "Heating source",
      rooms_nb: "Rooms number",
      floor: "Floor",
      elevator: "Elevator",
      ground_area: "Ground area",
      floors_nb: "Floors number",
      dwellings_nb: "Number of dwellings",
      construction_year: "Construction year",
      buildings_nb: "Buildings number",
      plot_surface: "Plot surface",
      municipality: "Municipality",
      street: "Street",
      locality: "Locality",
      region: "Region",
      canton: "Canton"
    },
    multi_select: {
      options: {
        mixed: "Mixed",
        residential: "Residential",
        commercial: "Commercial",
        listed_fund: "Listed fund",
        unlisted_fund: "Unlisted fund",
        real_estate_firm: "Real estate firm",
        foundation: "Foundation"
      }
    },
    groups: {
      information: "Information"
    },
    panel: {
      show: "Show"
    }
  },
  tabs: {
    legal_forms: "Legal forms",
    performance: "Performance",
    accounting: "Accounting",
    stock: "Stock performance",
    fees: "Fees",
    esg: "ESG",
    transaction: "Transaction",
    information: "Information",
    structure: "Structure",
    trading: "Trading",
    governance: "Governance",
    social: "Social",
    saved_screens: "Saved screens",
    type: "Type",
    financial: "Financial",
    energy: "Energy",
    dimension: "Dimension",
    location: "Location"
  }
};
var setting = {
  preference: {
    notification: {
      unsaved_screen: {
        sub: "Receive notification when you have unsaved screen.",
        title: "Turn on unsaved screen notification"
      },
      sub: "Enable necessary popup notification.",
      title: "Notification popup preference"
    },
    save_changes: "Save changes",
    unsaved_screen_notification: {
      sub: "If you check this box, you will no longer receive this notification. You can change back this preference on setting page.",
      title: "Don\u2019t show me this notification anymore"
    },
    language: {
      subtitle: "Select your favourite language.",
      title: "Language",
      success_message: "Language preference has been saved."
    }
  },
  profile: {
    email_preferences: {
      technical: {
        sub: "Recieve changelogs when the app has new features",
        title: "Technical emails"
      },
      marketing: {
        sub: "Recieve news about the company ",
        title: "Marketing Emails"
      },
      sub: "Change your email preferences",
      title: "Email preferences"
    },
    notification: {
      send_email_duplicated_msg: "A verification email has been sent within one minute, please check your mailbox.",
      send_email_success_msg: "Verification email has been sent, please check your mailbox.",
      wrong_first_name_msg: "Please input your correct first name.",
      change_pp_success_msg: "Your profile picture has been changed successfully.",
      error: "Something happened",
      success: "Successful"
    },
    picture: {
      format_error: "Probably wrong format or size.",
      error: "Image failed to be upload"
    },
    confirm_crop: "Confirm",
    email_verification_note: {
      button: "Re-send verification code",
      msg: "Please verify your email prior to use the platform.",
      title: "Verify your email"
    },
    confirm_delete_pp: {
      delete_button: "Delete account",
      cancel_button: "Cancel",
      msg: "To confirm that you want to delete your account, please input your First Name below and click the Delete account button.",
      modal_title: "Confirm to delete"
    },
    click_to_upload_file_constraints: "or drag and drop PNG, JPEG or JPG",
    click_to_upload: "Click to upload",
    save_changes: "Save changes",
    delete_my_account_title_sub: "There is no coming back.",
    delete_my_account_title: "Delete my account",
    profile_picture_title_sub: "Upload your profile picture.",
    profile_picture_title: "Profile picture",
    personal_info_title_sub: "Update your personal details.",
    personal_info_title: "Personal info"
  },
  connections: {
    modal: {
      copy_error: "Error during copy. Please copy manually",
      copied: "Key has been copied in clipboard",
      warning: {
        title: "Missing values",
        fill: "Please fill all fields"
      },
      delete: {
        subtitle: "Are you sure you want to delete these keys? This action cannot be undone.",
        title: "Delete {{ count }} keys"
      },
      delete_cancel: "Cancel",
      delete_confirm: "Delete",
      button_copy: "Copy and close",
      create: {
        subtitle: "Once created, this key will never be visible again.\nIf compromised, the key needs to be deleted and another one needs to be created.",
        title: "Create API key"
      },
      name: {
        label: "Key name",
        placeholder: "Example name"
      },
      expiration: {
        label: "Validity duration",
        placeholder: "Choose a duration"
      },
      button_create: "Create key"
    },
    options: {
      year: "{{ number }} Year",
      months: "{{ number }} Months",
      days: "{{ number }} Days",
      day: "{{ number }} Day"
    },
    days: {
      error: "Expiration days are required"
    },
    name: {
      error: "Name is too short"
    },
    table_name: {
      app: "App keys",
      personnal: "Personnal keys"
    },
    tokens_table: {
      col_name: {
        enterprise: "Enterprise",
        user: "User",
        expires_at: "Expiration date",
        created_at: "Creation date",
        name: "Key name"
      }
    }
  },
  seats: {
    payment_warning: {
      msg: {
        ended: "Your contract has ended. Have you take all advantages of our services?",
        no_slot: "You are assigned no slot. Please assign yourself the corresponding slot.",
        end_trial: "Your trial period has end. Have you take all advantages of our services?",
        unpaid: "Your enterprise is beyond payment due time. Your subscription needs to be regulated.",
        owner: "Please contact your related Account manager.",
        user: "Please contact this enterprise manager."
      },
      button: "Contact customer service",
      title: "There was a problem with your payment"
    },
    member_table: {
      col_name: {
        last_activity_date: "Active since",
        right: "Rights",
        role: "Role",
        name: "Name"
      }
    },
    no_slot_warning: {
      title: "You have no plan"
    },
    delete_user: {
      multiple: {
        confirm_msg: "Are you sure you want to delete these {{number}} users?"
      },
      success_msg: "User has been deleted.",
      confirm_msg: "Are you sure you want to delete this user {{firstName}} {{lastName}}?"
    },
    am_table: {
      col_name: {
        phone: "Account manager phone number",
        email: "Account manager email",
        name: "Account managers"
      }
    },
    plan_card: {
      info: "{{used}} of {{total}} seats used"
    },
    institutional: {
      name: "Institutional"
    },
    investor: {
      name: "Investor"
    },
    consumer: {
      name: "Consumer"
    }
  },
  security: {
    link_device: {
      notice: "We will unlink your current device from your account so that you can link your account to a new device. You will be logged out in order to link the new device. The previous TOTP will not work anymore. Please confirm if you want to proceed with the operation.",
      subtitle: "Link your account to a new device if you have no access to the current linked device anymore",
      title: "Link to new device"
    },
    warning_note: {
      content: "It is recommended to re-generate your recovery codes periodically. Proceed the operation with the button below.",
      title: "Re-generate your recovery codes"
    },
    renegerate_code: {
      success: "New recovery codes saved successfully.",
      title: "Regenerate recovery codes",
      subtitle: "Your previous recovery codes will not work anymore"
    },
    password: {
      disclaimer: "Password must contain at least 8 characters.",
      success_message: "Password successfully updated !",
      error_message: "Passwords must be identical",
      save_changes: "Save changes",
      confirm: "Confirm new password",
      subtitle: "Change your password",
      title: "Password"
    }
  },
  company: {
    set_contact_user: {
      success: "User has been set as contact user successfully."
    }
  },
  organization: {
    details: {
      no_contact_name: "No Contact user assigned",
      contact_subtitle: "Reference communication user",
      contact_name: "Contact user",
      phone: "Phone number",
      email: "Email address",
      zip: "ZIP",
      city: "City",
      address: "Address",
      country: "Country",
      name: "Company name",
      subtitle: "Update the details of your company.",
      title: "Company info"
    },
    notification: {
      update_success: "Updates have been applied successfully."
    },
    logo: {
      subtitle: "Update the logo of your company.",
      title: "Company logo"
    }
  },
  collaborator: {
    modal: {
      link_companies: {
        success_msg: "Enterprises managed by {{name}} is updated successfully.",
        subtitle: "Company",
        title: "Companies"
      }
    },
    col: {
      companies: "Companies",
      invitation_process: "Invitation process"
    },
    table_name: "Team members",
    reinvite_user: "An invitation email has been sent successfully."
  },
  companies: {
    table_name: "Company members"
  }
};
var unsaved_screener = {
  tooltip: {
    save_button: "Save your unsaved changes with this save button"
  },
  warning: {
    content: "You have an ongoing unsaved screen."
  },
  modal: {
    title: "You are about to leave the screener page"
  },
  button: {
    back_to_screener: "Go back to screener",
    leave_page: "Proceed without saving"
  }
};
var management = {
  nav: {
    preferences: {
      subtitle: "Manage your preferences",
      title: "Preferences"
    },
    connections: {
      subtitle: "Create and manage API keys to access quanthome data from an operating system, application, or other service.",
      title: " API"
    },
    edit_company: {
      subtitle: "Update information of a company",
      title: "Update company"
    },
    create_company: {
      subtitle: "Set up a new company by providing most of its information",
      title: "New company"
    },
    company: {
      subtitle: "Manage members & seat of an enterprise.",
      title: "Company"
    },
    requests: {
      subtitle: "All requests will be gathered here.",
      title: "Requests"
    },
    contracts: {
      title: "Contracts"
    },
    users: {
      subtitle: "All users of all companies are gathering here.",
      title: "Users"
    },
    teams: {
      subtitle: "Here is all you need to know about the people accessing the platform in your company.",
      title: "Teams"
    },
    companies: {
      title: "Companies",
      subtitle: "Here are all the companies subscribing to the platform."
    },
    profile: {
      subtitle: "Update your profile settings.",
      title: "Profile"
    },
    security: {
      subtitle: "Manage your password to access the platform.",
      title: "Security"
    },
    languages: {
      subtitle: "Manage your langages"
    },
    language: {
      title: "Language"
    },
    organization: {
      subtitle: "Here are all the information about the company you are working in.",
      title: "Organization"
    },
    seats: {
      subtitle: "Here is everything you need to know about seats and billing",
      title: "Seats"
    },
    collaborators: {
      subtitle: "Here is all you need to know about the people accessing the platform in your company.",
      title: "Collaborators"
    }
  },
  email: {
    addresses: {
      empty: "Your users will be displayed here",
      description: "Do it one by one or past a list of emails separated by a coma and validate with enter",
      title: "Email addresses"
    }
  },
  category: {
    communication: {
      title: "Communication"
    },
    administration: {
      title: "Administration"
    },
    customers: {
      title: "Customers"
    },
    personal: {
      title: "Personal"
    },
    company: {
      title: "Company"
    },
    team: {
      title: "Team"
    }
  },
  header: {
    gotoplatform: "Go to the platform",
    logout: "Log out"
  }
};
var double_range_input = {
  year_error_msg: "Please input a valid year",
  end: "end date",
  start: "start date",
  error_msg: "Min value should be smaller than the max.",
  clear: "clear",
  max: "max",
  min: "min"
};
var overview = {
  property: {
    mixed: "Institutional property",
    unlisted_fund: "Unlisted fund",
    real_estate_firm: "Real estate firm",
    listed_fund: "Listed fund",
    foundation: "Foundation property"
  }
};
var source_badge = {
  market_data: "Market data",
  quanthome_data: "Quanthome data",
  estimated: "Estimated",
  forecasted: "Forecasted",
  computed: "Computed",
  expected: "Expected",
  listed: "Listed",
  reported: "Reported",
  official: "Official"
};
var multiple_select = {
  clear: "clear",
  placeholder: {
    search: "Real estate"
  }
};
var single_select = {
  clear: "clear"
};
var screener_bar = {
  main_button: {
    screener: "Screener",
    launch: "Launch"
  }
};
var modal = {
  notifications: {
    subtitle_emailinfo: "manage the emails you will recieve from us",
    title_emailinfo: "You can now set email preferences",
    title_recoverycode: "Re-generate your recovery codes now",
    administration_linked: "You can now manage enterprise {{enterprise}}.",
    administration_unlinked: "Enterprise {{enterprise}} has been removed from your management list.",
    administration_examination: "Enterprise {{enterprise}} has been in examination state for one month.",
    administration_days_left: "The state of enterprise {{enterprise}} needs your attention: {{currentState}} state has {{daysLeft}} days left.",
    title_download: "Download",
    title_administration: "Administration",
    title: "Notifications",
    empty_message: "No notifications"
  },
  downloads: {
    not_available: "The download feature isn\u2019t available with your current pricing plan.",
    subtitle: {
      file_about_to_expire: "Your file is about to expire soon",
      file_failed: "Your file has failed due to an error - Please contact your administrator.",
      file_expired: "Your file has expired",
      file_ready: "Your file is ready to be downloaded",
      file_be_ready_soon: "Your file will be ready to be downloaded soon"
    },
    empty_message: "No downloads",
    title: "Downloads"
  },
  notification: {
    title_download: "Download"
  },
  history: {
    title: "History",
    empty_history: "No history"
  },
  preset: {
    empty_message: "No presets",
    title: "Presets"
  },
  historic: {
    title: "History"
  },
  leaving: {
    default_text: "You have clicked on a link provided by Quanthome SA that will direct you outside of the app.\nWe are not related to this website and or its owner. We highly recommend, for any cases while browsing the web, to never give your personal information without carefully reviewing if the website is (still) a trustworthy one.\nNo website should ask you for your Quanthome credentials nor will any AM ask for your password.",
    registry: {
      title: "Steps to follow in the external process"
    },
    title: "You're about to leave the app",
    buttons: {
      proceed: "Proceed"
    }
  },
  support: {
    submit_button: "Ask our support",
    select: {
      placeholder: "Choose",
      title: "Problem, message, need, idea...",
      need: "Need",
      message: "Message",
      problem: "Problem"
    },
    message: "Message",
    complete_title: "Support / Contact",
    error_message: {
      no_message: "You need to write a message",
      too_many_char: "Too many characters {{char_nb}} > 500",
      bad_category: "You need to select a category"
    },
    success: {
      message: "Your report has been successfully send to our client service",
      title: "Report send"
    },
    title: "Help",
    upload: "Upload"
  }
};
var line_chart = {
  placeholder: {
    no_data_selected: "Please select data to display"
  }
};
var empty_chart = {
  message: "No data"
};
var administration = {
  companies: {
    col_name: {
      developer_mode: "Developer mode",
      investor_users: "Investor users",
      institutional_users: "Institutional users",
      consumer_users: "Consumer users",
      free_institutional_seat: "Institutional seats left",
      used_institutional_seat: "Institutional used seats",
      total_institutional_seat: "Total institutional seats",
      free_investor_seat: "Investor seats left",
      used_investor_seat: "Investor used seats",
      total_investor_seat: "Total investor seats",
      free_consumer_seat: "Consumer seats left",
      used_consumer_seat: "Consumer used seats",
      total_consumer_seat: "Total consumer seats",
      used_all_seats: "Total used seats",
      total_all_seats: "Total seats",
      license_use: "License use",
      all_users: "Team members",
      contact_user_phone: "Contact user phone number",
      contact_user_email: "Contact user email",
      contact_user: "Contact user",
      related_am: "Related account manager",
      expiration_date: "Contract end date",
      subscription_start: "Subscription date",
      days_left: "Days left",
      state: "Contract type",
      name: "Company"
    },
    headers: {
      companies_count: "{{count}} Companies",
      member_count: "{{ count }} members"
    },
    actions: {
      delete_company: "Delete company",
      update_company_slots: "Update company slots",
      update_company: "Edit company settings"
    },
    cells: {
      user_count: "{{ count }} users"
    },
    teams: "teams",
    table_title: "Companies"
  },
  collaborators: {
    headers: {
      members_count: "{{ count }} Members "
    }
  },
  breadcrumb: {
    edit: "Edit",
    create_company: "Create",
    details: "Details",
    companies: "Companies"
  }
};
var treemap = {
  label: {
    label: "Label",
    value: "Value",
    percentage: "Percentage"
  },
  unit: {
    percentage: "%"
  }
};
var action = {
  label: {
    create_key: "Create key",
    invite_member: "Add member",
    create_user: "Create user",
    delete: "Delete"
  },
  portfolios: {
    name: {
      change_success: {
        message: "You successfully changed the portfolio name",
        title: "Name has been changed"
      }
    },
    remove: {
      loading: {
        message: "Removing {{ asset }}(s) from your portfolio"
      },
      confirmation: {
        question: "Are you sure that you want to remove the asset from this portfolio?",
        title: "Remove from portfolio"
      }
    }
  },
  users: {
    remove: {
      confirmation: {
        question: "Are you sure you want to remove these users?"
      }
    }
  },
  companies: {}
};
var pyramid_chart = {
  label: {
    age: "Age",
    unit: "%",
    percentage: "Percentage",
    woman: "Woman",
    man: "Man",
    group: "Group"
  }
};
var errors = {
  fii: {
    fii_portfolio_duplicated_dwelling_id: "All dwellings are already in this portfolio.",
    fii_invalid_dwelling_id: "No valid dwelling id.",
    fii_portfolio_duplicated_building_id: "All buildings are already in this portfolio.",
    fii_invalid_building_id: "No valid building id."
  }
};
var buildings = {
  type: {
    commercial: "Commercial",
    residential: "Residential",
    mixed: "Mixed"
  }
};
var items = {
  charts: {
    gradient: {
      subject: {
        index: "Index",
        indice: "Indice"
      }
    }
  },
  array: {
    columns: {
      title: {
        difference: "Difference",
        potential: "Estimated",
        real: "Reported / Listed"
      }
    }
  }
};
var gradient = {
  quartile_3: "3rd quartile",
  quartile_2: "Median",
  quartile_1: "1st quartile",
  chart: {
    no_data: "No data"
  },
  label: {
    high: "HIGH",
    low: "LOW"
  }
};
var badge = {
  beta: "Beta feature"
};
var valuations = {
  actions: {
    reset: "Reset"
  }
};
var header = {
  navbar: {
    institutions: "Institutions",
    map: "Map",
    funds: "Institutions",
    portfolios: "Portfolios",
    watchlist: "Watchlist",
    screener: "Real estate",
    dashboard: "Dashboard"
  },
  dropdown_menu: {
    my_company: "My company",
    settings: "Settings",
    logout: "Logout",
    back_office: "Administration",
    profile: "My Profile"
  }
};
var input = {
  select: "select",
  edit: "edit"
};
var view = {
  switch: {
    cards: "See cards",
    table: "See table"
  }
};
var unit = {
  chf_per_m2: "CHF/m\xB2"
};
var policy = {
  cookies: {
    what_are_cookies: "What are cookies?",
    types_list: {
      statistical: "Statistical Cookies: These help us understand how visitors interact with our Site, providing information on visited pages, time spent, and any issues, such as error messages. This aids in improving our Site's performance. ",
      third_party: "Third-party Cookies: Set by third parties we use for various services (e.g., Site analysis or advertising). QUANTHOME uses Google Analytics cookies, which provide information on user activities without identifying them. Google Analytics cookies don't collect Data about Site visitors. For more about Google Analytics, click <2>https://support.google.com/analytics/answer/6004245?hl=fr</11>.",
      functional: "Functional Cookies: Improve our Site's functioning, like remembering previously viewed content or registration details. These cookies enable personalized content and save you time by not needing to register again during future visits. We also use cookies for analytics and advertising. ",
      essential: "Essential Cookies: Necessary for our website's operation."
    },
    definition: "Cookies are small text files placed on your computer by websites you visit. They are widely used to ensure sites function or work more efficiently, and to provide information to site owners. Cookies might automatically process Data on your device and/or transfer Data about you to the site owner or third parties. You can manage the Site's cookies. ",
    more_information: "For more information, visit <2>https://gdpr.eu/cookies/</2>. Also, check your browser or electronic device's help section for specific instructions on managing cookies.",
    usage: "Why and how do we use cookies?",
    purpose: "We use cookies to enhance our Site's usability and functionality and to personalize or improve your browsing experience, making your next visit more pleasant. ",
    types_overview: "The cookies we use include:",
    consent: "We ask for your express consent for cookie use when accessing the Site. While accepting this Policy, you can refuse cookies. You can manage cookies at any time through your browser's settings or electronic device. However, please note that some cookies are essential for the Site's function, and its use might be altered if cookies are refused or blocked. ",
    types: "What types of cookies are used? "
  },
  data_correction: "You can contact us to know your Data, verify its accuracy, and request its completion, update, correction, or deletion as permitted by law, especially art. 32 LPD.",
  rights_overview: "You have the right to know and request the deletion, correction, or updating of the Data we process about you.",
  type_of_data: "What type of Data do you provide to QUANTHOME?",
  retention_request: "You can request QUANTHOME to retain your Data for potential future registration or to continue receiving our newsletter.",
  rights_exercise: "For further details or to exercise your rights, contact us at <2>contact@quanthome.com</2>.",
  legal_processing_objection: "You also have the right to request we stop any specific processing of Data obtained or processed in violation of the law and object to any Data processing for legitimate reasons.",
  title: "Privacy Policy",
  eea_rights_list: {
    objection: "Right to object to your Data's processing and withdraw consent (art. 21 GDPR),",
    processing_restriction: "Right to restrict processing upon request (art. 18 GDPR),",
    complaint: "Right to lodge a complaint with an authority in your usual place of residence or work, or where an infringement occurred (art. 77 GDPR),",
    forgotten: "Right to be forgotten (art. 17 GDPR),",
    rectification: "Right to rectification (art. 16 GDPR),",
    access: "Right to access your Data (art. 15 GDPR),",
    automated_decisions: "Right not to be subject to automated individual decisions (a process QUANTHOME doesn't use)."
  },
  data_list: {
    phone_number: "Your phone number",
    company_name: "The name or corporate name of the company you represent",
    first_name: "Your first name",
    last_name: "Your last name",
    email_address: "Your email address"
  },
  additional_information: "Any personal information you spontaneously share with us in email exchanges; Any other Data collected by cookies and the web server. More details about automatically collected Data can be found in article 10 of the Policy",
  data_retention: "How long does QUANTHOME keep your Data?",
  data_sharing: "Who might QUANTHOME share your Data with?",
  anonymization_use: "We might process your Data to remove identifiable information (anonymization), then using this anonymous data for purposes not outlined in the Policy (e.g., comparative analysis, campaign targeting, or developing and marketing new services)",
  data_usage: {
    account_creation: "Your name, last name, and, if applicable, your company's name, to create your account and allow you access to the Site and order products. This is contractually required.",
    legal_age_verification: "Your birth date ensures you're of legal age to enter contracts with us. This is based on our legitimate interest.",
    cookies_analytics: "Data transmitted by Site cookies is for internal analytics and advertising. This is based on our legitimate interest and your consent when required by law.",
    legal_compliance: "All transmitted Data can be processed to comply with legal obligations and other legal imperatives",
    contact_information: "Your address, email, and phone number allow us to contact you. These are required for product sales. When used for newsletters or promotional communication, it's based on your consent.",
    payment_verification: "Your bank details validate your payment for products. This is contractually required."
  },
  data_handling: "How does QUANTHOME handle your Data?",
  cookies_section: "Cookies",
  policy_date: "This privacy policy (the 'Policy') is dated September 30, 2023. It may be reviewed regularly (see article 11).",
  hosting_details: "Data collected by QUANTHOME is hosted in Switzerland and processed by Infomaniak",
  data_provision_consequences: "You have no legal or contractual obligation to provide us with Data. However, refusing to provide certain Data or exercising the rights mentioned in this article 9 might prevent us from providing you our products, including Site access. ",
  law_compliance: "We process your Data in compliance with applicable laws, especially Swiss and European data protection laws, adopting measures to prevent unauthorized access, disclosure, alteration, or destruction.",
  sharing_list: {
    corporate_events: "In mergers, acquisitions, or restructuring events, or possible bankruptcy procedures, your Data might be transferred to third parties as an asset of QUANTHOME. We'll strive to inform you of such transfers beforehand, but cannot guarantee an opt-out",
    service_execution: "With any third party necessary for service execution (e.g., employees, affiliated companies, payment providers, logistic companies, our hosting provider, and IT personnel).",
    legal_requirements: "With any third party legally required due to a directive from judicial or governmental authorities or in compliance with our legal obligations."
  },
  why_collect_data: "Why does QUANTHOME collect this Data?",
  data_deletion_request: "If you want us to delete your Data from our systems, send a request to the contact below, which we'll comply with unless we need to keep your Data for legal reasons or another legitimate purpose.",
  data_hosting: "Where is your Data hosted?",
  what_is_personal_data: "What is Personal Data?",
  security_measures: "QUANTHOME strives to ensure your Data's security, preventing any unauthorized access, use, modification, or disclosure.",
  updates: "We reserve the right to make changes and corrections to these provisions. Please check this page regularly for updates and new information. ",
  processing_methods: "We may process your Data either automatically or manually, always implementing suitable security measures.",
  personal_data_definition: "Personal data ('Data') refers to any information relating to an individual. Such data can either directly identify you (e.g., your name, email address, or social security number) or do so indirectly by combining various information about you (e.g., your computer's IP address, your credit card, or phone number).",
  transfer_consent: "By accessing the Site and sharing Data, you expressly consent to such transfers.",
  retention_period: "QUANTHOME retains your Data only as long as necessary, typically 3 years after your last contact with QUANTHOME. Due to our legal obligations, some data, especially those related to our contractual relationship, must be retained for at least 10 years.",
  consent_withdrawal: "If we rely on your consent to process your Data, we'll seek your free and specific consent, providing clear instructions. You can revoke your consent at any time. You also have the right to request the portability of your automatically processed Data based on your consent or contractual necessity.",
  credential_safety: "To protect your Data's confidentiality, you must keep your credentials safe and not disclose them to anyone. You are responsible for any use of services offered on the Site using your credentials.",
  consent: "If the reasons above aren't seen as legitimate, you consent to the processing of the Data for the mentioned purposes by accepting this Policy.",
  third_party_security: "We also endeavor to ensure any third party your Data might be shared with (as described above) handles it securely, maintaining its confidentiality and integrity.",
  company_info: "Quanthome SA, hereinafter referred to as 'QUANTHOME', 'we', or 'our', is a public limited company registered in the commercial register of the Canton of Vaud, located at Avenue de Mon-Repos 24, 1005 Lausanne, Switzerland.",
  sharing_overview: "QUANTHOME may share your Data as follows:",
  anonymization_alternative: "Instead of destroying or deleting your Data, QUANTHOME might permanently anonymize it, making identification impossible",
  data_processing_basis: "We process your Data based on legitimate reasons specified below, or based on your consent:",
  access_right: "Unless otherwise provided by applicable law, you have the right to know if we process Data concerning you at any time and exercise your access right per art. 25 LPD.",
  security_breach_protocol: "Please inform us immediately of any suspected or confirmed misuse of your credentials. Remember, no security measure is foolproof",
  non_identifying_data_use: "Further non-identifying data, including aggregated or anonymized data, might be used to manage services, improve customer relations, diagnose technical issues, and gather aggregated demographic information",
  agreement: 'By accessing the website <1>www.quanthome.com</1> ("Site"), which we operate, you expressly agree to our processing your data in accordance with this Policy.',
  eea_rights: "If you are an EEA resident, you specifically have the following rights:",
  data_provided: "Data that QUANTHOME automatically collects when you access the Site or that you voluntarily provide when creating an account or later, during orders (fields marked with an asterisk are required, and additional fields can be provided optionally):",
  your_rights: "What rights do you have regarding your Data?",
  data_protection: "How does QUANTHOME protect your Data?",
  terms_invitation: "We also invite you to review our Terms of Use and our End User License Agreement",
  inquiries: "For any inquiries regarding this Policy or your data's use, please contact us at <2>contact@quanthome.com</2> or call +41 21 312 16 93.",
  eea_representative: "Our representative in the EEA is [to be completed]. The following guidelines apply to any data you provide to QUANTHOME.",
  introduction: "Your privacy matters to us. As the data controller, Quanthome SA is committed to processing the personal data you share with us transparently and in accordance with applicable law.",
  international_transfers: "In some circumstances, especially for technical reasons, your Data might be transferred outside Switzerland or the European Union. In such cases, third parties will guarantee adequate measures ensuring the legal requirements and protection of your rights are met.",
  legal_updates: "Updating legal information."
};
var login = {
  error: "Error while logging"
};
var watchlist = {
  buildings: {
    empty: "No buildings watchlist"
  },
  dwellings: {
    empty: "No dwellings watchlist"
  }
};
var download = {
  action_label: "Download CSV",
  request_created: "Request created."
};
var profile_train = {
  tooltip: {
    more: "{{count}} more"
  }
};
var impressum = {
  mail: "E-mail address: ",
  address: "Postal address: Avenue Mon-Repos 24, 1005 Lausanne",
  text: "Impressum Quanthome SA uses the website app.quanthome.com to provide data on every real"
};
var notification = {
  terminated_session: {
    message: "You might be connected on an other device or the session life time had been reached.",
    title: "Session terminated"
  }
};
var paywall = {
  button: {
    logout: "Logout"
  },
  subtitle: "Please contact your Account manager.",
  message: {
    error: "There is an error with your subscription.",
    no_slot: "You currently have no right to use the application.",
    slot_out_of_scope: "This feature is not included in your permission slots.",
    out_of_scope: "This feature is out of your enterprise subscription.",
    end_trial: "Your trial ended."
  }
};
var dashboard = {
  trend: {
    title: "Swiss market trend: net rent per m\xB2"
  },
  rent: {
    title: "Rent per m\xB2 per canton"
  },
  indicators: {
    title: "Indicators",
    reference_interest_rate: "Reference Interest Rate",
    switzerland_vacancy_rate: "Swiss Vacancy Rate",
    ipc_lik: "CPI Consumer Price Index (IPC/LIK)",
    snb_policy_rate: "Swiss National Bank Policy Rate"
  },
  indices: {
    title: "Indices",
    construction_price_index_total: "Swiss Construction Index - Total",
    construction_price_index_civil_engineering: "Swiss Construction Index - Civil engineering",
    construction_price_index_building: "Swiss Construction Index - Building",
    swiss_residential_property_price: "Swiss Residential Property Price Index"
  },
  error: {
    feature: {
      rights: "You don\u2019t have access to this feature"
    }
  },
  empty: {
    funds: "You don\u2019t have any funds",
    portfolios: "You don\u2019t have any portfolios"
  }
};
var widget = {
  side_menu: {
    country: "Country",
    canton: "Canton",
    municipality: "Municipality"
  }
};
var status = {
  resend: "Re-send",
  not_complete: "Not completed",
  completed: "Completed"
};
var option = {
  no_plan: "No plan",
  sudo: "Sudo",
  consumer: "Consumer",
  investor: "Investor",
  institutional: "Institutional",
  am: "Account manager",
  admin: "Admin",
  user: "User",
  manager: "Manager",
  owner: "Owner",
  triage: "Triage"
};
var boarding = {
  suggested_otp: "Suggested OTP app:"
};
var footer = {
  confidential_policy: "Confidential Policy",
  impressum: "Impressum"
};
var potfolios = {
  sections: {
    assets: {
      title: "Inventory"
    },
    social: {
      title: "Social"
    },
    environmental: {
      title: "Environmental"
    },
    financials: {
      title: "Financials"
    },
    description: {
      title: "Description"
    }
  },
  tiles: {
    charges: {
      title: "Charges"
    },
    evolution: {
      title: "Evolution"
    },
    revenue: {
      title: "Revenue"
    }
  },
  widget: {
    charges: {
      title: "Charges"
    },
    revenue: {
      title: "Revenue"
    }
  }
};
var inputs = {
  placeholder: {
    input_name: "Input name",
    preset_name: "Preset name",
    choose_option: "Choose an option"
  }
};
var entity_selector = {
  search: "Search",
  button: {
    save: "Save changes",
    cancel: "Cancel"
  }
};
var users = {
  role: {
    user: "User"
  },
  roles: {
    triage: "Triage",
    owner: "Owner"
  }
};
var general = {
  app_wall: {
    redirect: {
      message: "You don't have access to the app currently. We will redirect you to the settings\u2026"
    }
  }
};
var password = "New password";
var traits = {
  contact: {
    office_phone: "Business phone number"
  },
  email: "Email address",
  name: {
    last: "Last name",
    first: "First name"
  }
};
var search = {
  title: "Search result",
  nothing_found: "Nothing found",
  placeholder: "Address, EGID, Name, Fund, ISIN..."
};
var en_default = {
  table_actions,
  table_header,
  table,
  funds,
  units,
  boarding_page,
  asset,
  preset,
  tile,
  error,
  companies,
  balance_sheet,
  actions,
  common,
  income_statement,
  global,
  asset_page,
  portfolios,
  portfolio,
  candle_stick_chart,
  screener,
  setting,
  unsaved_screener,
  management,
  double_range_input,
  overview,
  source_badge,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  errors,
  buildings,
  items,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  users,
  general,
  password,
  traits,
  search
};

export {
  table_actions,
  table_header,
  table,
  funds,
  units,
  boarding_page,
  asset,
  preset,
  tile,
  error,
  companies,
  balance_sheet,
  actions,
  common,
  income_statement,
  global,
  asset_page,
  portfolios,
  portfolio,
  candle_stick_chart,
  screener,
  setting,
  unsaved_screener,
  management,
  double_range_input,
  overview,
  source_badge,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  errors,
  buildings,
  items,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  users,
  general,
  password,
  traits,
  search,
  en_default
};
